import {AnswerEquality, AnswerQuestion} from '../../../../../../../model/EventQuestion';
import {isEmpty, uniq} from 'lodash';
import {ILanguageParams} from '../../../../../../../core/constants';

export const checkFeedbackCheckChoice = (answers, items: AnswerQuestion[]): boolean | null => {
  const correctAnswers = items.filter(o => o.correctAnswer).map(a => a.id);
  if (isEmpty(correctAnswers) || (!isEmpty(correctAnswers) && isEmpty(answers))) {
    return null;
  } else {
    return correctAnswers.every(aId => answers.includes(aId) &&
      !answers.some(oId => !correctAnswers.includes(oId)));
  }
};

export const checkFeedbackMatching = (answers, items: AnswerQuestion[]): boolean | null => {
  if (isEmpty(answers)) {
    return null;
  } else {
    if (items.length === answers.length) {
      const answersObject: {[answerId: string]: string[]} = answers.reduce((accum, item) => {
        const answer: string[] = item.split('-');
        accum[answer[0]] = answer.filter(id => id !== answer[0]);
        return accum;
      }, {});
      return items.every(a => !isEmpty(a.matching) && !isEmpty(answersObject[a.id]) &&
        answersObject[a.id].every(aId => a.matching.includes(aId)));
    } else {
      return null;
    }
  }
};

export const checkFeedbackGapFillingMatchingMap = (answers, items: AnswerQuestion[], correctEquality: AnswerEquality[]): boolean | null => {
  if (isEmpty(answers)) {
    return null;
  } else {
    if (uniq(correctEquality.map(o => o.id)).length === answers.length) {
      return answers.every(answer => {
        const arr = answer.split('=');
        const groupId = arr[0];
        const aIds = arr.filter(id => id !== groupId);
        const correctIds = correctEquality.filter(o => o.id === groupId).map(o => o.answerId);
        return aIds.every(aId => correctIds.includes(aId));
      });
    } else {
      return null;
    }
  }
};

export const checkFeedbackTextGapFilling = (answers, items: AnswerQuestion[],
                                            correctEquality: AnswerEquality[], languageParams: ILanguageParams): boolean | null => {
  if (isEmpty(answers)) {
    return null;
  } else {
    if (uniq(correctEquality.map(o => o.id)).length === answers.length) {
      return answers.every(answer => {
        const arr = answer.split('=');
        const groupId = arr[0];
        const ans = arr[1] ?? '';
        const correctStrings = correctEquality.filter(o => o.id === groupId)
          .map(o => items.find(a => a.id === o.answerId)?.getAnswerByLanguage(languageParams)?.toLowerCase().trim());
        return ans.length && correctStrings.includes(ans.toLowerCase().trim());
      });
    } else {
      return null;
    }
  }
};

export const checkFeedbackCheckboxGapFilling = (answers, items: AnswerQuestion[], correctEquality: AnswerEquality[]): boolean | null => {
  if (!isEmpty(answers)) {
    if (!isEmpty(correctEquality)) {
      const correctIds = correctEquality.filter(eq => !!eq.answerValue).map(o => o.id);
      const answersEqIds = correctEquality.filter(eq => answers.includes(eq.id)).map(o => o.id);
      return !isEmpty(answersEqIds) && answersEqIds.length === correctIds.length && answersEqIds.every(id => correctIds.includes(id));
    }
    return null;
  } else {
    return null;
  }
};

export const checkFeedbackText = (answers, items: AnswerQuestion[],
                                  correctEquality: AnswerEquality[], languageParams: ILanguageParams): boolean | null => {
  if (isEmpty(answers)) {
    return null;
  }
  return items.some(it => it.getAnswerByLanguage(languageParams).toLowerCase() === answers[0].toLowerCase());
};

