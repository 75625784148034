import {DependencyQuestion} from './content/DependencyQuestion';
import {Constants, DEPENDENCY, ILanguageParams, SMILES_RATING_SETS_NAMES, TCaption} from '../core/constants';
import {WordCloudTemplate} from './content/WordCloudTemplate';
import {isEmpty, merge} from 'lodash';
import {UtilsService} from '../core/utils.service';
import {QUESTION_TYPES_COMPONENTS} from '../modules/content-container/components/quiz/quiz-components/shared/quiz-quiestion-types';

export interface IRegistrationQuestionnaireAnswers {
  [userId: string]: {[sectionId: string]: {[questionId: string]: {answers: any}}};
}

export interface ICheckFeedback {checkResult: boolean; feedbackMessage: string; }

export interface ISimpleQuestion {
  questionId: string;
  caption: string;
  optional: number;
  storypoint: number;
  items: AnswerQuestion[];
}

export interface IGapGroup {
  id: string;
  name: string;
}

export interface IGapGroupMatching {
  id: string;
  matchingId: string;
  name: string;
}

export interface IGapAnswerData {
  id: string;
  answerId?: string;
  answerValue?: any;
  answerCaption?: any;
}

export interface IGapResultData {
  correctCount: number;
  errorCount: number;
  correctPercent: number;
  errorPercent: number;
}

export interface IGapResultDataMap {
  [gapId: string]: IGapResultData;
}


export class EventQuestion {
  id: string = this.genId();
  eventId: string = null;
  timelineId: string = null;
  storypoint: number = null;
  orderIndex = 0;
  caption: TCaption = null;
  liveResults = true;
  items: AnswerQuestion[] = [];
  matchingItems: AnswerQuestionMatching[];
  correctEquality: AnswerEquality[];
  taskText: TCaption;
  answers = {}; // internal system fields, user for create question result data by users answers not save to DB.
  optional: boolean = null; // if true not used when check answers for all questionnaire questions
  dependency: DependencyQuestion = new DependencyQuestion();
  directFeedback: boolean;
  correctFeedbackMessage: string;
  incorrectFeedbackMessage: string;
  showCorrectAnswers: boolean;
  useCorrectAnswers: boolean;
  options: any = {};
  files: string[] = [];

  public static genQuestionId(increment: number = 0): string {
    return 'Q' + ((new Date()).getTime() + increment).toString();
  }

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    if (obj.hasOwnProperty('id')) {
      this.id = obj.id;
    }
    if (obj.hasOwnProperty('eventId')) {
      this.eventId = obj.eventId;
    }
    if (obj.hasOwnProperty('timelineId')) {
      this.timelineId = obj.timelineId;
    }
    if (obj.storypoint != null) {
      this.storypoint = obj.storypoint;
    }
    if (obj.hasOwnProperty('liveResults')) {
      this.liveResults = obj.liveResults;
    }
    if (obj.hasOwnProperty('caption')) {
      this.caption = obj.caption;
    }
    if (obj.hasOwnProperty('items')) {
      this.items = (obj.items || []).map(o => new AnswerQuestion(o));
    }
    if (obj.hasOwnProperty('matchingItems')) {
      this.matchingItems = (obj.matchingItems || []).map(o => new AnswerQuestionMatching(o));
    }
    if (obj.hasOwnProperty('answers')) {
      this.answers = obj.answers;
    }
    if (obj.hasOwnProperty('orderIndex')) {
      this.orderIndex = obj.orderIndex;
    }
    if (obj.hasOwnProperty('optional')) {
      this.optional = obj.optional;
    }
    if (obj.hasOwnProperty('dependency')) {
      this.dependency = obj.dependency;
    }
    if (obj.hasOwnProperty('directFeedback')) {
      this.directFeedback = obj.directFeedback;
    }
    if (obj.hasOwnProperty('correctFeedbackMessage')) {
      this.correctFeedbackMessage = obj.correctFeedbackMessage;
    }
    if (obj.hasOwnProperty('incorrectFeedbackMessage')) {
      this.incorrectFeedbackMessage = obj.incorrectFeedbackMessage;
    }
    if (obj.hasOwnProperty('showCorrectAnswers')) {
      this.showCorrectAnswers = obj.showCorrectAnswers;
    }
    if (obj.hasOwnProperty('useCorrectAnswers')) {
      this.useCorrectAnswers = obj.useCorrectAnswers;
    }
    if (obj.hasOwnProperty('correctEquality')) {
      this.correctEquality = (obj.correctEquality || []).map(o => new AnswerEquality(o));
    }
    if (obj.hasOwnProperty('taskText')) {
      this.taskText = obj.taskText;
    }
    if (obj.hasOwnProperty('options')) {
      this.options = obj.options;
    }
    if (obj.hasOwnProperty('files')) {
      this.files = obj.files;
    }
  }

  private genId(): string {
    return 'Q' + UtilsService.createId();
  }

  setRequiredFromExternal(eventId: string, timelineId: string, orderIndex?): EventQuestion {
    this.eventId = eventId;
    this.timelineId = timelineId;
    if (orderIndex) {
      this.orderIndex = orderIndex;
    }
    return this;
  }

  getCaptionByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(this, 'caption', languageParams);
  }

  setCaptionByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(this, 'caption', value, languageParams);
  }

  getTaskTextByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(this, 'taskText', languageParams);
  }

  setTaskTextByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(this, 'taskText', value, languageParams);
  }

  /**
   * Get dependency by different conditions.
   * Use this for get true dependency object.
   * @returns {DependencyQuestion} - object or undefined
   */
  getDependency(): DependencyQuestion {
    return this.dependency && this.dependency.dependencyType !== DEPENDENCY.NONE &&
      this.dependency.questionId && this.dependency.answerIdList && this.dependency.answerIdList.length > 0 ?
        new DependencyQuestion(this.dependency) : undefined;
  }

  /**
   * User answers.
   * @param userId
   * @returns {string[]} - array of answers id. always not null.
   */
  getUserAnswers(userId): string[] {
    let result: string[] = [];
    const obj = this.answers[userId];
    if (obj) {
      result = this.objectValues(obj);
    }
    return result;
  }

  private objectValues(object) {
    return Object.keys(object).map(function (key) {
      return object[key];
    });

  }

  /**
   * return feedback message
   * @param userId
   * @param languageParams
   */
  checkCorrectUserAnswers(userId, languageParams: ILanguageParams): ICheckFeedback {
    return this.checkFeedback(userId, null, languageParams);
  }

  /**
   * return feedback message
   * @param userAnswers
   * @param languageParams
   */
  checkCorrectAnswers(userAnswers: any, languageParams: ILanguageParams): ICheckFeedback {
    return this.checkFeedback(null, userAnswers, languageParams);
  }

  private checkFeedback(userId, userAnswers, languageParams: ILanguageParams): ICheckFeedback {
    const correctFeedback = () => this.correctFeedbackMessage ? this.correctFeedbackMessage :
      'edit_dialog.question_dialog.input.direct.feedback.correct.message';
    const incorrectFeedback = () => this.incorrectFeedbackMessage ? this.incorrectFeedbackMessage :
      'edit_dialog.question_dialog.input.direct.feedback.incorrect.message';
    const answers = userId ? this.getUserAnswers(userId) : userAnswers;
    if (QUESTION_TYPES_COMPONENTS[this.storypoint].directFeedbackCheck) {
      const chk = QUESTION_TYPES_COMPONENTS[this.storypoint].directFeedbackCheck(answers, this.items, this.correctEquality, languageParams);
      return chk !== null ? {checkResult: chk, feedbackMessage: chk ? correctFeedback() : incorrectFeedback()} : null;
    }
  }

  toObject() {
    delete this.answers;
    const checkUnsupportedValue = (object) => {
      Object.keys(object)
        .forEach(key => {
          if ((object[key] === undefined || object[key] === null || typeof object[key] === 'function')) {
            delete object[key];
          } else if (typeof object[key] === 'object') {
            if (typeof object[key]['toObject'] !== 'undefined') {
              object[key] = object[key].toObject();
            } else {
              checkUnsupportedValue(object[key]);
            }
          }
        });
    };
    const obj = {...this};
    checkUnsupportedValue(obj);
    return obj;
  }

}

export class AnswerQuestion {
  id: string = this.genId();
  answer: TCaption = null;
  orderIndex = 0;
  correctAnswer: boolean = null;
  matching: string[] = [];

  public static genAnswerId(increment: number = 0): string {
    return 'AQ' + ((new Date()).getTime() + increment).toString();
  }

  constructor (obj?) {
    if (!obj) {
      return;
    }
    if (obj.id) {
      this.id = obj.id;
    }
    if (obj.hasOwnProperty('answer')) {
      this.answer = obj.answer;
    }
    if (obj.hasOwnProperty('orderIndex')) {
      this.orderIndex = obj.orderIndex;
    }
    if (obj.hasOwnProperty('correctAnswer')) {
      this.correctAnswer = obj.correctAnswer;
    }
    if (obj.hasOwnProperty('matching')) {
      this.matching = obj.matching;
    }
  }

  genId(): string {
    return 'AQ' + UtilsService.createId();
  }

  getAnswerByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(this, 'answer', languageParams);
  }

  setAnswerByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(this, 'answer', value, languageParams);
  }

  toObject() {
    return {...this};
  }
}

export interface IMapPosition {
  top: number;
  left: number;
  bottom: number;
  right: number;
  width: number;
  height: number;
}

export class AnswerQuestionMatching {
  id: string = this.genId();
  answerMatching: TCaption;
  orderIndex = 0;

  constructor (obj?) {
    if (!obj) {
      return;
    }
    if (obj.id) {
      this.id = obj.id;
    }
    if (obj.answerMatching) {
      this.answerMatching = obj.answerMatching;
    }
    if (obj.orderIndex) {
      this.orderIndex = obj.orderIndex;
    }
  }

  genId(): string {
    return 'AQM' + UtilsService.createId();
  }

  getAnswerByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(this, 'answerMatching', languageParams);
  }

  setAnswerByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(this, 'answerMatching', value, languageParams);
  }

  toObject() {
    return {...this};
  }
}

export class AnswerEquality {
  id: string = this.genId();
  answerId: string;
  answerValue: any;
  mapPosition: IMapPosition; // used for set position on image map if question type of "MATCHING_MAP"

  constructor (obj?) {
    if (!obj) {
      return;
    }
    if (obj.id) {
      this.id = obj.id;
    }
    if (obj.answerId) {
      this.answerId = obj.answerId;
    }
    if (obj.answerValue) {
      this.answerValue = obj.answerValue;
    }
    if (obj.mapPosition) {
      this.mapPosition = obj.mapPosition;
    }
  }

  private genId(): string {
    return 'AEQ' + UtilsService.createId();
  }

  toObject() {
    return {...this};
  }

}

export class TableRowAnswerQuestion extends AnswerQuestion {
  constructor(object, public languageParams: ILanguageParams) {
    super(object);
  }

  get rowCaption() {
    return this.getAnswerByLanguage(this.languageParams);
  }

  set rowCaption(value: string) {
    this.setAnswerByLanguage(value, this.languageParams);
  }
}

export class TableRowAnswerQuestionMatching extends AnswerQuestionMatching {
  constructor(object, public languageParams: ILanguageParams) {
    super(object);
  }

  get rowCaption() {
    return this.getAnswerByLanguage(this.languageParams);
  }

  set rowCaption(value: string) {
    this.setAnswerByLanguage(value, this.languageParams);
  }
}
