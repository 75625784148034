// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import {version} from './appVersion';

export const environment = {
  production: false
  , name: 'DEV'
  , appName: 'HKVBS Timeline'
  , organizationName: 'Timeline International Inc.'
  , lang: 'de'
  , version: version
  , debug: {
    analytics: true,
    trace: true,
    console: true,
    crashReport: true
  }
  , domains: ['timeline-p20-dev.firebaseapp.com', 'p20-dev.timeline.click', 'localhost:4200']
  , firebase: {
    apiKey: 'AIzaSyBVjIOF5nBS9zdG0qcP-XlGsTBTylXtUxM',
    // authDomain: 'timeline-p20-dev.firebaseapp.com',
    authDomain: 'p20-dev.timeline.click',
    databaseURL: 'https://timeline-p20-dev.firebaseio.com',
    projectId: 'timeline-p20-dev',
    storageBucket: 'timeline-p20-dev.appspot.com',
    // messagingSenderId: '466245750393',
    appId: '1:466245750393:web:96e5bafea2215584324ea8',
    measurementId: 'G-QZBVHMFT07'
  }
  // , BACKEND_BASE: 'http://localhost:8080'
  // , BACKEND_BASE: 'https://timeline-p20-dev.appspot.com'
  , BACKEND_BASE: 'https://api.p20-dev.timeline.click'
  , providers: [
      {provider: 'hkvbs', caption: 'HKVBS', value: 'hkvbs', disabled: true},
      {provider: 'gapi', caption: 'Google', value: 'other', disabled: false},
      {provider: 'hkvbs-azure', caption: 'Azure', value: 'hkvbs-azure', disabled: true},
      {provider: 'microsoft', caption: 'HKVBS', value: 'microsoft', disabled: false},
      {provider: 'anonymous', caption: 'Guest', value: 'anonymous', disabled: true},
      {provider: 'email', caption: 'Email', value: 'email', disabled: true}
    ]
  , defaultProvider: 'other'
  , gapi: {
    client_id: '1028271825075-0mm0s2bptlqh1nvd1f5a2eu95l5blr67.apps.googleusercontent.com',
    apiKey: 'AIzaSyBVjIOF5nBS9zdG0qcP-XlGsTBTylXtUxM',
    scopes: [
      'openid'
      , 'profile'
      , 'email'
    ],
    calendar: {
      enabled: false,
      DISCOVERY_DOCS: [
        'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'
      ],
      scopes: [
        'https://www.googleapis.com/auth/calendar.events'
      ]
    },
    geolocation: {
      apiKey: 'AIzaSyCE4JO44_pRDRMJ5EWc-d-qPd6o9ME0Ok8'
    }
  },
  email: {
    externalUsers: false,
    selfRegistration: false
  },
  hkvbs: {
    API_URL: 'https://auth.hkvbs.ch/connect/authorize',
    client_id: 'timeline_prod',
    // redirect_uri: 'http://localhost:4200/auth/hkvbs',
    redirect_uri: 'https://timeline-p20-dev.firebaseapp.com/auth/hkvbs',
    scopes: [
      'openid',
      'email',
      'pirouetteInfo',
      'profile',
      'roles',
      'eDirectory'
    ]
  },
  'hkvbs-azure': {
    API_URL: 'https://login.microsoftonline.com/c330ae58-58bb-44f9-b132-b478d3a9ce58/oauth2/v2.0/authorize',
    client_id: '36e109df-3a89-47ec-9b55-920b6913fc2c',
    // redirect_uri: 'http://localhost:4200/auth/hkvbs',
    redirect_uri: 'https://timeline-p20-dev.firebaseapp.com/auth/hkvbs',
    scopes: [
      'openid',
      'email',
      'profile',
      'offline_access',
      'User.Read'
    ]
  },
  microsoft: {
    API_URL: '',
    client_id: '36e109df-3a89-47ec-9b55-920b6913fc2c',
    tenant_id: 'c330ae58-58bb-44f9-b132-b478d3a9ce58',
    redirect_uri: '',
    scopes: [
      'openid',
      'email',
      'profile',
      'offline_access',
      'User.Read',
      '36e109df-3a89-47ec-9b55-920b6913fc2c/FileAccess'
    ]
  },
  api_key: 'AIzaSyC2UGpOX8qj4d9TRXwBV5AORNz5ddYkfuY',
  hiddenMenuItems: {
    'my-time': false
  },
  meeting: {
    event: 'zoom',
    jitsi: {
      domain: 'meet.kv.timeline.click'
    },
    zoom: {
      meetingClientName: 'env20'
    },
    zoom_sdk: {
      password: 'P93UG8'
    },
    daily: {
      api: 'https://timelinedev.daily.co/'
    },
    doNotCreateMeetingForInstantGroup: false
  },
  features: {
    enableSlotMode: true,
    enableDebug: false,
    hideSelfLearningUsersDetailDefaultValue: false,
    importSlots: false,
    importSlotsUrl: '',
    myKnowledge: false,
    recordings: false
  },
  companyName: 'HKVBS',
  linkToFileStore: 'https://www.hkvbs.ch/filrjs/filr.html?lw=classes',
  visibleAuthenticationCodeSettingControl: true,
  enableSocialPresentation: true,
  client_id: '0020'
};
