import {CONTAINER_ITEM_TYPE, ContentContainerItem, IColumnRange, IContainerItemRect} from '../../../model/content/ContentContainer';
import {TextNoteComponent} from '../components/text-note/text-note/text-note.component';
import {PictureGalleryComponent} from '../components/picture-gallery/picture-gallery/picture-gallery.component';
import {PictureGalleryService} from '../components/picture-gallery/picture-gallery.service';
import {AppUser} from '../../../model/AppUser';
import {BehaviorSubject, Subscription} from 'rxjs';
import {VideoStreamComponent} from '../components/video-stream/video-stream/video-stream.component';
import {PdfDocumentComponent} from '../components/pdf-document/pdf-document/pdf-document.component';
import {PdfDocumentService} from '../components/pdf-document/pdf-document.service';
import {VideoStreamService} from '../components/video-stream/video-stream.service';
import {QuizQuestionSheetComponent} from '../components/quiz/quiz-question-sheet/quiz-question-sheet.component';
import {QuizService} from '../components/quiz/quiz.service';
import {ZoomifyDocumentComponent} from '../components/zoomify-document/zoomify-document/zoomify-document.component';
import {ZoomifyDocumentService} from '../components/zoomify-document/zoomify-document.service';
import {SketchfabDocumentComponent} from '../components/sketchfab-document/sketchfab-document/sketchfab-document.component';
import {EasyzoomDocumentComponent} from '../components/easyzoom-document/easyzoom-document/easyzoom-document.component';
import {TaskDocumentComponent} from '../components/task/task-document/task-document.component';
import {TaskService} from '../components/task/task.service';
import {HtmlNoteComponent} from '../components/html-note/html-note/html-note.component';
import {HighlightTableComponent} from '../components/highlight-table/highlight-table/highlight-table.component';
import {EmbeddedFrameComponent} from '../components/embedded-frame/embedded-frame/embedded-frame.component';
import {EMBEDDED_FRAME_PROVIDER} from '../components/embedded-frame/embedded-frame-model/embedded-frame-model';
import {QuizFinalizeServiceService} from '../components/quiz/quiz-finalize-service.service';
import {TextNoteMergeServiceService} from '../components/text-note/text-note-merge-service.service';
import {QUIZ_PREVIEW_MENU} from '../components/quiz/quiz-model/quiz';
import {TextNoteService} from '../components/text-note/text-note.service';

export interface IComponentOptions {
  heightAutoFit?: boolean;
  autoExtendHeightAfterEdit?: boolean;
  autoFitHeightIfNull?: boolean; // set height = auto if height = null. (e.g. used: height = null for content created by import)
  languageFontSizeAuto?: boolean;
  restrictResizeWhenHorizontalOverflow?: boolean;
  minHeight?: number; // in width of column (e.g. 1 = columnWidth * 1, 1.2 = columnWidth * 1.2 and etc.)
  minWidth?: number; // in column count, only integer value between 1..12 (e.g. minWidth = 2)
  canUsedSingleMode?: boolean;
  multilingualNotSupport?: boolean; // now used in editor mode with heightAutoFit = true for select height = auto
  notAllowFullScreenDefault?: boolean;
  mobileModeHeightAuto?: boolean;
  mobileModeWidthMax?: boolean;
}

type TComponentClass = typeof TextNoteComponent | typeof PictureGalleryComponent |
  typeof VideoStreamComponent | typeof PdfDocumentComponent | typeof QuizQuestionSheetComponent |
  typeof ZoomifyDocumentComponent | typeof SketchfabDocumentComponent | typeof EasyzoomDocumentComponent |
  typeof TaskDocumentComponent | typeof HtmlNoteComponent | typeof HighlightTableComponent | typeof EmbeddedFrameComponent;

type TComponentService = typeof PictureGalleryService | typeof PdfDocumentService | typeof VideoStreamService | typeof QuizService |
  typeof ZoomifyDocumentService | typeof TaskService | typeof TextNoteService;

export type TComponentFinalizeService = typeof QuizFinalizeServiceService;

export type TComponentMergeService = typeof TextNoteMergeServiceService;

export enum SUBMENU_GROUPS {
  EMBEDDED_COMPONENTS = 'EMBEDDED_COMPONENTS'
}

/**
 * e.g. for pictures gallery:
 *  objectsListName: 'pictures'
 *  objectUrlName: 'src'
 */
export interface IURLToBASE64Scheme {
  objectsListName?: string;
  objectsStoreFolder?: string;
  objectIdName: string;
  objectUrlName: string;
}

export interface ICustomMenuAction {
  title: string;
  icon: string;
  action: string;
  tablerIcon?: string;
}

interface IContentComponentBasic {
  svgIcon: string;
  icon?: string;
  iconClass?: string;
  title: string;
  buttonOrderIndex: number;
  menuButton?: boolean;
  urlToBASE64Scheme?: IURLToBASE64Scheme; // used for exchange through clipboard
  ignoreCopyToClipboard?: boolean;
  submenuGroupName?: string;
  provider?: string;
  submenuItems?: IContentComponentBasic[];

  // if the component uses the preview menu in the editor then
  // the parameter is equal to an empty array or list of actions to control the component externally.
  previewMenu?: ICustomMenuAction[];
}

export interface IContentComponent extends IContentComponentBasic {
  componentClass: TComponentClass;
  componentService?: TComponentService;
  finalizeService?: TComponentFinalizeService;
  mergeService?: TComponentMergeService;
  options?: IComponentOptions;
}

export interface IContentComponentMap {
  [type: string]: IContentComponent;
}

export const COMPONENTS: IContentComponentMap = {
  [CONTAINER_ITEM_TYPE.TEXT]: {
    componentClass: TextNoteComponent,
    componentService: TextNoteService,
    mergeService: TextNoteMergeServiceService,
    svgIcon: 'ct-text-note',
    iconClass: 'standard-icon-material',
    icon: 'text-plus',
    title: 'content.container.text',
    buttonOrderIndex: 10,
    urlToBASE64Scheme: {objectsListName: 'files', objectIdName: 'id', objectUrlName: 'src'},
    options: {
      minWidth: 1,
      heightAutoFit: true,
      restrictResizeWhenHorizontalOverflow: true,
      languageFontSizeAuto: true,
      notAllowFullScreenDefault: true}
  },
  [CONTAINER_ITEM_TYPE.IMAGE]: {
    componentClass: PictureGalleryComponent,
    componentService: PictureGalleryService,
    svgIcon: 'ct-picture-gallery',
    iconClass: 'standard-icon-material',
    icon: 'photo',
    buttonOrderIndex: 20,
    title: 'content.container.image',
    previewMenu: [],
    urlToBASE64Scheme: {objectsListName: 'pictures', objectIdName: 'id', objectUrlName: 'src'},
    options: {minHeight: 1, minWidth: 1, canUsedSingleMode: true}},
  [CONTAINER_ITEM_TYPE.VIDEO]: {
    componentClass: VideoStreamComponent,
    componentService: VideoStreamService,
    svgIcon: 'ct-video',
    iconClass: 'standard-icon-material',
    icon: 'movie',
    buttonOrderIndex: 30,
    title: 'content.container.video',
    options: {heightAutoFit: true, canUsedSingleMode: true}
  },
  [CONTAINER_ITEM_TYPE.QUIZ]: {
    componentClass: QuizQuestionSheetComponent,
    componentService: QuizService,
    finalizeService: QuizFinalizeServiceService,
    svgIcon: 'ct-quiz',
    icon: 'chart-bar',
    iconClass: 'standard-icon-material rotate-90',
    buttonOrderIndex: 40,
    title: 'content.container.quiz',
    previewMenu: QUIZ_PREVIEW_MENU,
    urlToBASE64Scheme: {objectsListName: 'questions', objectIdName: 'id', objectUrlName: 'files'},
    options: {
      minHeight: 4,
      minWidth: 4,
      autoFitHeightIfNull: true,
      autoExtendHeightAfterEdit: true,
      canUsedSingleMode: true,
      mobileModeHeightAuto: true}
  },
  [CONTAINER_ITEM_TYPE.PDF]: {
    componentClass: PdfDocumentComponent,
    componentService: PdfDocumentService,
    svgIcon: 'ct-pdf',
    buttonOrderIndex: 50,
    menuButton: true,
    title: 'content.container.pdf',
    urlToBASE64Scheme: {objectIdName: 'id', objectUrlName: 'src'},
    options: {minWidth: 4, heightAutoFit: true, multilingualNotSupport: true}
  },
  [CONTAINER_ITEM_TYPE.TASK]: {
    componentClass: TaskDocumentComponent,
    componentService: TaskService,
    svgIcon: 'ct-task',
    buttonOrderIndex: 60,
    menuButton: true,
    title: 'content.container.task',
    urlToBASE64Scheme: {objectsListName: 'files', objectIdName: 'id', objectUrlName: 'src', objectsStoreFolder: 'task'},
    options: {minWidth: 4, minHeight: 7, canUsedSingleMode: true, mobileModeWidthMax: true}
  },
  [CONTAINER_ITEM_TYPE.HIGHLIGHT]: {
    componentClass: HighlightTableComponent,
    svgIcon: 'ct-highlight',
    icon: 'list',
    iconClass: 'standard-icon-material',
    buttonOrderIndex: 70,
    menuButton: true,
    title: 'content.container.highlight.table',
    options: {
      minWidth: 1,
      heightAutoFit: true,
      restrictResizeWhenHorizontalOverflow: true,
      languageFontSizeAuto: true}
  },
  [CONTAINER_ITEM_TYPE.HTML]: {
    componentClass: HtmlNoteComponent,
    svgIcon: 'ct-html',
    iconClass: 'standard-content-icon',
    buttonOrderIndex: 80,
    menuButton: true,
    title: 'content.container.html',
    options: {
      minWidth: 1,
      heightAutoFit: true,
      restrictResizeWhenHorizontalOverflow: true}
  },
  [CONTAINER_ITEM_TYPE.EMBEDDED_FRAME]: {
    componentClass: EmbeddedFrameComponent,
    svgIcon: 'ct-embedded',
    buttonOrderIndex: 10,
    submenuGroupName: SUBMENU_GROUPS.EMBEDDED_COMPONENTS,
    title: 'content.container.embedded.frames',
    options: {
      minWidth: 3,
      minHeight: 3,
      mobileModeWidthMax: true
    },
    submenuItems: [
      {
        svgIcon: 'ct-h5p',
        iconClass: 'h5p-icon',
        title: 'content.container.embedded.frames.h5p',
        provider: EMBEDDED_FRAME_PROVIDER.PRESSBOOKS,
        buttonOrderIndex: 10
      },
      {
        svgIcon: 'ct-learning-snacks',
        iconClass: 'standard-content-icon',
        title: 'content.container.embedded.frames.learning.snacks',
        provider: EMBEDDED_FRAME_PROVIDER.LEARNING_SNACKS,
        buttonOrderIndex: 20
      },
      {
        svgIcon: 'ct-learning-apps',
        iconClass: 'standard-content-icon',
        title: 'content.container.embedded.frames.learning.apps',
        provider: EMBEDDED_FRAME_PROVIDER.LEARNING_APPS,
        buttonOrderIndex: 30
      }
    ],
  },
  [CONTAINER_ITEM_TYPE.SKETCHFAB]: {
    componentClass: SketchfabDocumentComponent,
    svgIcon: 'ct-sketchfab',
    iconClass: 'sketchfab-icon',
    buttonOrderIndex: 100,
    menuButton: true,
    title: 'content.container.sketchfab',
    options: {minWidth: 3, minHeight: 3, canUsedSingleMode: true, mobileModeWidthMax: true}
  },
  [CONTAINER_ITEM_TYPE.EASYZOOM]: {
    componentClass: EasyzoomDocumentComponent,
    svgIcon: 'ct-easyzoom',
    iconClass: 'easyzoom-icon',
    buttonOrderIndex: 110,
    menuButton: true,
    title: 'content.container.easyzoom',
    options: {minWidth: 3, minHeight: 3, canUsedSingleMode: true}
  },
  [CONTAINER_ITEM_TYPE.ZOOMIFY]: {
    componentClass: ZoomifyDocumentComponent,
    componentService: ZoomifyDocumentService,
    svgIcon: 'ct-zoomify',
    iconClass: 'zoomify-icon',
    buttonOrderIndex: 120,
    menuButton: true,
    title: 'content.container.zoomify',
    ignoreCopyToClipboard: true,
    options: {minWidth: 3, heightAutoFit: true, canUsedSingleMode: true}
  }
};

export enum ITEM_MARKER {
  NEW = '*',
  DELETED = '-'
}

export type TUser = Pick<AppUser, 'email' | 'fullName' | 'picture' | 'department'>;

export interface IPoint {
  x: number;
  y: number;
  color?: string;
  width?: number;
}

export interface IRect {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface ILeftWidth {
  left: number;
  width: number;
}

export enum MAIN_ELEMENTS {
  BODY = 'contentBodyElement',
  WRAPPER = 'contentWrapper',
  LINE_PATH_MENU_TRIGGER = 'linePathMenuTrigger',
  SCROLL_GRID = 'scrollGrid',
  SCROLL_GRID_TAG = 'scroll-grid',
  SCROLL_GRID_ROW_TAG = 'row',
  GRID_COLUMN_ID = 'column-1'
}

export const EDITOR_CONFIG = {
  'GRID_COLUMNS_COUNT': 24, // change same value in css variable $column-count
  'DEFAULT_NEW_SLIDE_HEIGHT': 3 // => columnWidth * 3
};

export const EDITOR_CONFIG_12 = {
  'GRID_COLUMNS_COUNT': 12, // change same value in css variable $column-count
  'DEFAULT_NEW_SLIDE_HEIGHT': 1.5 // => columnWidth * 1.5
};

export const EDITOR_CONFIG_24 = {
  'GRID_COLUMNS_COUNT': 24, // change same value in css variable $column-count
  'DEFAULT_NEW_SLIDE_HEIGHT': 3 // => columnWidth * 3
};

export const CSS_CONTAINER_POSITION_GRID_FORMAT_PARAM_NAME = '--container-position-grid-format';

export const MAX_WIDTH = '1024px';

export interface ISlide {
  id: string;
  element: HTMLElement;
  rect: IRect;
  columnLength: number;
  startColumn: number;
  resizeSubscription: Subscription;
  actionSubscription: Subscription;
  followMeSubscription: Subscription;
  componentResizeSubscription: Subscription;
  containerItem: ContentContainerItem;
  loaded$: BehaviorSubject<boolean>;
}

export enum RESIZE_TYPE {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  HEIGHT = 'height',
  LEFT = 'left',
  MOVE = 'move',
  STICK_RIGHT = 'stick-right',
  STICK_RIGHT_OVERFLOW = 'stick-right-overflow',
  STICK_LEFT = 'stick-left',
  STICK_LEFT_OVERFLOW = 'stick-left-overflow',
  STICK_TO_TOP_SLIDE = 'stick-to-top-slide',
  STICK_AFTER_DROP = 'stick-after-drop',
  START_RESIZE = 'start-resize',
  END_RESIZE = 'end-resize'
}

export enum SLIDE_ACTION {
  DELETE_SLIDE = 'delete-slide',
  UPDATE_SLIDE = 'update-slide',
  CLEAN_UP_SLIDE = 'clean-up-slide',
  ADD_SLIDE = 'add-slide',
  FULLSCREEN_SLIDE = 'fullscreen-slide',
  EDIT_CANCEL = 'edit_cancel',
  EDIT_APPLY = 'edit_apply',
  DELETE_SLIDE_AND_CLOSE_WITH_SAVE = 'delete_slide_and_close_with_save'
}

export interface IDelta {
  offset?: number;
  top?: number;
  left?: number;
  height?: number;
}

export interface IResizeEvent {
  id: string;
  delta?: IDelta;
  resizeType: RESIZE_TYPE;
}

export interface ISlideAction {
  id?: string;
  type?: CONTAINER_ITEM_TYPE;
  data?: any;
  options?: IComponentOptions;
  action: SLIDE_ACTION;
}

export enum EXTERNAL_ACTION {
  ADD_NEW_SLIDE = 'add-new-slide',
  SET_SINGLE_MODE = 'set-single-mode',
  SET_ANIMATION_MODE = 'set-animation-mode',
  TRANSLATE_ALL = 'translate-all',
  AUTO_ADJUST_FONT_SIZE_ALL = 'auto-adjust-font-size-for-all-languages',
  EDIT_SLIDE = 'edit-slide',
  CHECK_IS_MODIFIED = 'check_is_modified',
  SET_MULTIPLE_COLUMN_MODE = 'set-multiple-column-mode',
}

export enum INTERNAL_ACTION {
  CHANGE_CURRENT_LANGUAGE = 'change_current_language',
  SLIDE_EDITOR_APPLY = 'slide_editor_apply',
  SLIDE_EDITOR_CANCEL = 'slide_editor_cancel',
  CLOSE_EDITOR_SAVE_AND_GO_TO_LINK = 'close_editor_save_and_goto_link',
  IS_MODIFIED = 'is_modified'
}

export type TAction = INTERNAL_ACTION | EXTERNAL_ACTION;

export interface IAction {
  action: TAction;
  value?: any;
}

export const FULLSCREEN_FOLLOW_NAME = 'fullscreen';
export const ANIMATION_FOLLOW_NAME = 'animation';

export const BACKGROUND_COLOR_LIST = [
  '#F1F3F5', '#F5F5F2', '#DBD6D1', '#FFE8DE',
  '#FAD6E7', '#FF8782', '#F2D4FF', '#F8D8324D',
  '#3D7BD54D', '#BDE3FF', '#44AC424D'];
export const NOT_USER_BACKGROUND_COLOR = '#F1F3F5';

export interface IAccessOptions {
  [name: string]: boolean;
}

export enum TOOLBAR_BUTTON {
  EDIT = 'edit',
  CLEAN_UP = 'clean_up',
  DELETE = 'delete',
  BACKGROUND_COLOR = 'background_color',
  ANIMATE_NUMBER = 'animate_number',
  HIDE_FROM_SINGLE_MODE = 'hide_from_single_mode',
  COPY_TO_CLIPBOARD = 'copy_to_clipboard',
  EXTRACT_BELOW_TO_NEW_CONTENT = 'extract_below_to_new_content',
  PREVIEW = 'preview'
}

export interface IMultilingual {
  multilingual: boolean;
  usedLanguages: string[];
}

export interface IContentContainerDOMDetail {
  containerHeight: number;
}

export interface IExternalOptions {
  hideContentTagsReference?: boolean;
}

export const DISABLED_TOOLBAR_BUTTONS: IAccessOptions = {
  [TOOLBAR_BUTTON.DELETE]: true,
  [TOOLBAR_BUTTON.CLEAN_UP]: true,
  [TOOLBAR_BUTTON.HIDE_FROM_SINGLE_MODE]: true,
  [TOOLBAR_BUTTON.BACKGROUND_COLOR]: true,
  [TOOLBAR_BUTTON.COPY_TO_CLIPBOARD]: true,
  [TOOLBAR_BUTTON.EXTRACT_BELOW_TO_NEW_CONTENT]: true,
  [TOOLBAR_BUTTON.ANIMATE_NUMBER]: true
};

export const HIDDEN_TOOLBAR_BUTTONS_FOR_NEW_ITEM: IAccessOptions = {
  [TOOLBAR_BUTTON.COPY_TO_CLIPBOARD]: true,
  [TOOLBAR_BUTTON.EXTRACT_BELOW_TO_NEW_CONTENT]: true
};

export const RESTRICTED_MY_KNOWLEDGE_TYPES: IAccessOptions = {
  [CONTAINER_ITEM_TYPE.QUIZ]: true,
  [CONTAINER_ITEM_TYPE.TASK]: true,
  [CONTAINER_ITEM_TYPE.ZOOMIFY]: true
};

export const RESTRICTED_TYPES_USED_TAGS: IAccessOptions = {
  [CONTAINER_ITEM_TYPE.QUIZ]: true,
  [CONTAINER_ITEM_TYPE.TASK]: true
};

export interface IDOMPosition {
  id: string;
  rect: DOMRect;
}

export interface IOptionsHash {
  optionsHash: string;
  rect: IContainerItemRect;
}

export interface IContentOptions {
  animate?: boolean;
  containersCount?: number;
}

export const DEFAULT_COLUMN_RANGES: IColumnRange[] = [
  {start: 1, length: 12},
  {start: 13, length: 12}
];

export const COLUMN_RANGE_LINE_CLASS = 'column-range-line';
export const COLUMN_RANGE_LINE_SUBCLASS_WARNING = 'warning';
export const COLUMN_ELEMENT_ID_PREFIX = 'column-';
export const CONTAINER_BACKGROUND_COLOR_PROPERTY = '--app-time-line-content-container__background-color';
