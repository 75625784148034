import { Component, computed, ElementRef, Injector, Input, signal } from '@angular/core';
import { AbstractQuizQuestionResultsComponent } from '../../shared/results/abstract-quiz-question-results-component';
import { isEmpty, sum, union } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { QUIZ_VIEW_MODE, QuizService } from '../../../quiz.service';

interface ISummary {
  answer: string;
  count: number;
  correct: boolean;
}

@Component({
  selector: 'app-question-text-balloons-results',
  templateUrl: './question-text-balloons-results.component.html',
  styleUrl: './question-text-balloons-results.component.scss'
})
export class QuestionTextBalloonsResultsComponent extends AbstractQuizQuestionResultsComponent {
  viewMode = computed(() => this.quizService.quizViewMode().find(x => x.questionId === this.qKey)?.mode || QUIZ_VIEW_MODE.LIST_VIEW);
  answers$ = new BehaviorSubject<any[]>([]);
  currentSelectedAnswer = signal<null | string>(null);

  constructor(protected injector: Injector,
    protected elementRef: ElementRef,
            private quizService: QuizService) {
    super(injector, elementRef);
  }

  protected onReceiveQuestionAnswers() {
    this.summaryQuestionAnswers$.pipe(this.takeUntilAlive())
      .subscribe(value => {
        if (isEmpty(value)) {
          this.answers$.next([]);
        } else if (Array.isArray(value)) {
          this.answers$.next(value.map(o => JSON.parse(o)));
        } else {
          const answers = Object.keys(value)
            .map(s => new Object({
              answer: s,
              count: value[s],
              correct: !!this.question.items.find(a => a.getAnswerByLanguage(this.languageParams).toLowerCase() === s.toLowerCase())
            }) as ISummary);
          const correct = this.question.items.map(it => new Object({
            answer: it.getAnswerByLanguage(this.languageParams),
            count: 0,
            correct: true
          }) as ISummary);
          correct.forEach(it => {
            it.count = sum(answers.filter(a => it.answer.toLowerCase() === a.answer.toLowerCase()).map(v => v.count));
          });
          this.answers$.next(union(correct, answers.filter(a => !a.correct)).sort((a, b) => a.correct ? -1 : 1));
        }
      });
  }
}
