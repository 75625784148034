import {Injectable} from '@angular/core';
import {UIPlugin, Uppy} from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Webcam from '@uppy/webcam';
import Url from '@uppy/url';
import Unsplash from '@uppy/unsplash';
import {environment} from '../../environments/environment';
import {assign} from 'lodash';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {CommonService} from './common.service';
import Transloadit from '@uppy/transloadit'

/*
* How to use:
*  uppy: any;
*  In constructor prepare uppy:
*     this.uppy = this.uploadService.createImageUploader((result) => {
*       this.uploadService.closeModal(this.uppy);
*     });
*  Call uppy dialog:
*  upload() {
*    this.uploadService.openUploadWindow(this.uppy);
*  }
* */

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private static MAX_FILE_SIZE = 100 * 1024 * 1024;
  private static MAX_NUMBER_OF_FILES = 100;
  private static UPLOAD_TYPES = [
    // .doc
    'application/msword'
    // .docx
    , 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    // .xml
    , 'text/xml'
    // .pdf
    , 'application/pdf'
    // .xls
    , 'application/vnd.ms-excel'
    // .xlsx
    , 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ];

  //uppy: Uppy = new Uppy({ debug: true, autoProceed: true });

  public uppySettings = {
    debug: !environment.production,
    autoProceed: false,
    restrictions: {
      maxFileSize: UploadService.MAX_FILE_SIZE
      , maxNumberOfFiles: UploadService.MAX_NUMBER_OF_FILES
      , minNumberOfFiles: 1
      , allowedFileTypes: UploadService.UPLOAD_TYPES
    }
  };

  public dashboardOptions = {
    inline: false,
    target: 'body',
    replaceTargetContent: false,
    note: this.common.utils.i18n('upload.note'),
    maxHeight: 450,
    showProgressDetails: true,
    proudlyDisplayPoweredByUppy: false,
  };

  constructor(private common: CommonService,
              private afDB: AngularFireDatabase) {
  }

  public createCustomUploader(options: any, onComplete?): Uppy {
    let uppySettings = this.uppySettings;
    if (options && options.uppySettings) {
      uppySettings = assign({}, this.uppySettings, options.uppySettings);
    }
    let dashboardOptions = this.dashboardOptions;
    if (options && options.dashboardOptions) {
      dashboardOptions = assign({}, this.dashboardOptions, options.dashboardOptions);
    }
    let uppy;
    if (!options.uploaderType) {
        if (options.hasUrlUnsplashOpt) {
          /**Link and Splash upload options */
          uppy = new Uppy(uppySettings)
            .use(Dashboard, dashboardOptions)
            .use(Webcam, {
              target: Dashboard
            })
            .use(Unsplash, {
              target: Dashboard,
              companionUrl: Transloadit.COMPANION,
            })
            .use(MemoryStorage, {});
        } else {
          uppy = new Uppy(uppySettings)
            .use(Dashboard, dashboardOptions)
            .use(Webcam, {
              target: Dashboard
            })
            .use(MemoryStorage, {});
        }
    } else if (options.uploaderType === UPLOAD_TYPE.PDF) {
      uppy = new Uppy(uppySettings)
        .use(Dashboard, dashboardOptions)
        .use(MemoryStorage, {});
    } else if (options.uploaderType === UPLOAD_TYPE.AUDIO) {
      uppy = new Uppy(uppySettings)
        .use(Dashboard, dashboardOptions)
        .use(MemoryStorage, {});
    } else if (options.uploaderType === UPLOAD_TYPE.XML) {
      uppy = new Uppy(uppySettings)
        .use(Dashboard, dashboardOptions)
        .use(MemoryStorage, {});
    } else if (options.uploaderType === UPLOAD_TYPE.ZIF) {
      uppy = new Uppy(uppySettings)
        .use(Dashboard, dashboardOptions)
        .use(MemoryStorage, {});
    } else if (options.uploaderType === UPLOAD_TYPE.PNG) {
      uppy = new Uppy(uppySettings)
        .use(Dashboard, dashboardOptions)
        .use(MemoryStorage, {});
    } else if (options.uploaderType === UPLOAD_TYPE.ANY_DOCUMENT) {
      uppy = new Uppy(uppySettings)
        .use(Dashboard, dashboardOptions)
        .use(MemoryStorage, {});
    }
    if (onComplete) {
      uppy.on('complete', result => {
        this.common.log.debug('successful files:', result.successful);
        this.common.log.debug('failed files:', result.failed);
        onComplete(result);
      });
    }

    return uppy;
  }

  public createUploader(onComplete?): Uppy {
    return this.createCustomUploader(null,  onComplete);
  }

  public openUploadWindow(uppy: Uppy) {
    (<any>uppy).getPlugin('Dashboard').openModal();
  }

  public destroy(uppy: Uppy) {
    if (uppy) {
      uppy.close();
    }
  }

  public closeModal(uppy: Uppy) {
    if (uppy) {
      uppy.reset();
      (<any>uppy).getPlugin('Dashboard').closeModal();
    }
  }

  public createFileUploader(uploadType, settings, onSuccess?, onCancel?, hasUrlUnsplashOpt?: boolean): Uppy {
    if (uploadType === UPLOAD_TYPE.IMAGE || uploadType === UPLOAD_TYPE.PECHA_KUCHA) {
      return this.createImageUploader(settings, onSuccess, onCancel, hasUrlUnsplashOpt);
    }
    if (uploadType === UPLOAD_TYPE.SINGLE_IMAGE) {
      return this.createSingleImageUploader(settings, onSuccess, onCancel);
    }
    if (uploadType === UPLOAD_TYPE.PDF) {
      return this.createPdfUploader(settings, onSuccess, onCancel);
    }
    if (uploadType === UPLOAD_TYPE.ZIF) {
      return this.createZifUploader(settings, onSuccess, onCancel);
    }
    if (uploadType === UPLOAD_TYPE.PNG) {
      return this.createPNGUploader(settings, onSuccess, onCancel);
    }
    if (uploadType === UPLOAD_TYPE.ANY_DOCUMENT) {
      return this.createAnyDocumentUploader(settings, onSuccess, onCancel);
    }
    if (uploadType === UPLOAD_TYPE.XML) {
      return this.createXMLUploader(settings, onSuccess, onCancel);
    }
    if (uploadType === UPLOAD_TYPE.AUDIO) {
      return this.createAudioUploader(settings, onSuccess, onCancel);
    }
    return null;
  }

  private createImageUploader(settings, onSuccess?, onCancel?, hasUrlUnsplashOpt?: boolean): Uppy {
    const options = {uppySettings: {}, dashboardOptions: {}};
    options.uppySettings = settings || {
      restrictions: {
        maxNumberOfFiles: 25,
        // allowedFileTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml' ],
        allowedFileTypes: ['image/jpeg', 'image/png', 'image/gif'],
        maxFileSize: 1024 * 1024 * 20
      }
    };
    options.uppySettings['onBeforeFileAdded'] = () => true;
    options['hasUrlUnsplashOpt'] = hasUrlUnsplashOpt;
    options.dashboardOptions = {
      trigger: '.uppy-hidden-trigger',
      note: this.common.utils.i18n('upload.note.img'),
      onRequestCloseModal: () => {
        if (onCancel) {
          onCancel();
        }
        this.closeModal(uppy);
      }
    };
    const uppy = this.createCustomUploader(options, (result) => {
      if (result && result.successful && result.successful.length) {
        if (onSuccess) {
          onSuccess(result.successful);
        }
        this.closeModal(uppy);
      } else {
        this.closeModal(uppy);
      }
    });
    return uppy;
  }

  private createSingleImageUploader(settings, onSuccess?, onCancel?): Uppy {
    const options = {uppySettings: {}, dashboardOptions: {}};
    options.uppySettings = settings || {
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'],
        maxFileSize: 1024 * 1024 * 9
      }
    };
    options.uppySettings['onBeforeFileAdded'] = () => true;
    options.dashboardOptions = {
      trigger: '.uppy-hidden-trigger',
      note: this.common.utils.i18n('upload.note.single.img'),
      closeModalOnClickOutside: true,
      onRequestCloseModal: () => {
        if (onCancel) {
          onCancel();
        }
        this.closeModal(uppy);
      }
    };
    const uppy = this.createCustomUploader(options, (result) => {
      if (result && result.successful && result.successful.length) {
        if (onSuccess) {
          onSuccess(result.successful);
        }
        this.closeModal(uppy);
      } else {
        this.closeModal(uppy);
      }
    });
    return uppy;
  }

  private createPdfUploader(settings, onSuccess?, onCancel?): Uppy {
    const options = {uppySettings: {}, dashboardOptions: {}, uploaderType: null};
    options.uppySettings = settings || {
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['application/pdf'],
        maxFileSize: 1024 * 1024 * 20
      }
    };
    options.uppySettings['onBeforeFileAdded'] = () => true;
    options.dashboardOptions = {
      trigger: '.uppy-hidden-trigger',
      note: this.common.utils.i18n('upload.note.pdf'),
      onRequestCloseModal: () => {
        if (onCancel) {
          onCancel();
        }
        this.closeModal(uppy);
      }
    };
    options.uploaderType = UPLOAD_TYPE.PDF;
    const uppy = this.createCustomUploader(options, (result) => {
      if (result && result.successful && result.successful.length) {
        if (onSuccess) {
          onSuccess(result.successful);
        }
        this.closeModal(uppy);
      } else {
        this.closeModal(uppy);
      }
    });
    return uppy;
  }

  private createAudioUploader(settings, onSuccess?, onCancel?): Uppy {
    const options = {uppySettings: {}, dashboardOptions: {}, uploaderType: null};
    options.uppySettings = settings || {
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['audio/mpeg'],
        maxFileSize: 1024 * 1024 * 20
      }
    };
    options.uppySettings['onBeforeFileAdded'] = () => true;
    options.dashboardOptions = {
      trigger: '.uppy-hidden-trigger',
      note: this.common.utils.i18n('upload.note.audio'),
      onRequestCloseModal: () => {
        if (onCancel) {
          onCancel();
        }
        this.closeModal(uppy);
      }
    };
    options.uploaderType = UPLOAD_TYPE.AUDIO;
    const uppy = this.createCustomUploader(options, (result) => {
      if (result && result.successful && result.successful.length) {
        if (onSuccess) {
          onSuccess(result.successful);
        }
        this.closeModal(uppy);
      } else {
        this.closeModal(uppy);
      }
    });
    return uppy;
  }

  private createXMLUploader(settings, onSuccess?, onCancel?): Uppy {
    const options = {uppySettings: {}, dashboardOptions: {}, uploaderType: null};
    options.uppySettings = settings || {
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['.XML'],
        maxFileSize: 1024 * 1024 * 20
      }
    };
    options.uppySettings['onBeforeFileAdded'] = () => true;
    options.dashboardOptions = {
      trigger: '.uppy-hidden-trigger',
      note: this.common.utils.i18n('upload.note.xml'),
      onRequestCloseModal: () => {
        if (onCancel) {
          onCancel();
        }
        this.closeModal(uppy);
      }
    };
    options.uploaderType = UPLOAD_TYPE.XML;
    const uppy = this.createCustomUploader(options, (result) => {
      if (result && result.successful && result.successful.length) {
        if (onSuccess) {
          onSuccess(result.successful);
        }
        this.closeModal(uppy);
      } else {
        this.closeModal(uppy);
      }
    });
    return uppy;
  }

  private createZifUploader(settings, onSuccess?, onCancel?): Uppy {
    const options = {uppySettings: {}, dashboardOptions: {}, uploaderType: null};
    options.uppySettings = settings || {
      restrictions: {
        maxNumberOfFiles: 1,
        maxFileSize: 1024 * 1024 * 500
      }
    };
    options.uppySettings['onBeforeFileAdded'] = () => true;
    options.dashboardOptions = {
      trigger: '.uppy-hidden-trigger',
      note: this.common.utils.i18n('upload.note.zif'),
      onRequestCloseModal: () => {
        if (onCancel) {
          onCancel();
        }
        this.closeModal(uppy);
      }
    };
    options.uploaderType = UPLOAD_TYPE.ZIF;
    const uppy = this.createCustomUploader(options, (result) => {
      if (result && result.successful && result.successful.length) {
        if (onSuccess) {
          onSuccess(result.successful);
        }
        this.closeModal(uppy);
      } else {
        this.closeModal(uppy);
      }
    });
    return uppy;
  }

  private createPNGUploader(settings, onSuccess?, onCancel?): Uppy {
    const options = {uppySettings: {}, dashboardOptions: {}, uploaderType: null};
    options.uppySettings = settings || {
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/png'],
        maxFileSize: 1024 * 1024
      }
    };
    options.uppySettings['onBeforeFileAdded'] = () => true;
    options.dashboardOptions = {
    trigger: '.uppy-hidden-trigger',
      note: this.common.utils.i18n('upload.note.png'),
      onRequestCloseModal: () => {
        if (onCancel) {
          onCancel();
        }
        this.closeModal(uppy);
      }
    };
    options.uploaderType = UPLOAD_TYPE.PNG;
    const uppy = this.createCustomUploader(options, (result) => {
      if (result && result.successful && result.successful.length) {
        if (onSuccess) {
          onSuccess(result.successful);
        }
        this.closeModal(uppy);
      } else {
        this.closeModal(uppy);
      }
    });
    return uppy;
  }

  private createAnyDocumentUploader(settings, onSuccess?, onCancel?): Uppy {
    const options = {uppySettings: {}, dashboardOptions: {}, uploaderType: null};
    options.uppySettings = settings || {
      restrictions: {
        maxNumberOfFiles: 10,
        allowedFileTypes: ['.AC2', '.PSD', '.NEF', '.CR2', '.CCD', '.AI', '.SVG', '.GIF', '.TXT', '.MP3', '.ODP', '.KEY', '.WAV', '.M4A',
          '.zip', '.csv', '.log', '.odt', '.rtf',
          'image/png', 'image/jpeg', 'application/pdf',
          'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
        maxFileSize: 1024 * 1024 * 20
      }
    };
    options.uppySettings['onBeforeFileAdded'] = () => true;
    options.dashboardOptions = {
      trigger: '.uppy-hidden-trigger',
      note: this.common.utils.i18n('upload.note.any.document'),
      onRequestCloseModal: () => {
        if (onCancel) {
          onCancel();
        }
        this.closeModal(uppy);
      }
    };
    options.uploaderType = UPLOAD_TYPE.ANY_DOCUMENT;
    const uppy = this.createCustomUploader(options, (result) => {
      if (result && result.successful && result.successful.length) {
        if (onSuccess) {
          onSuccess(result.successful);
        }
        this.closeModal(uppy);
      } else {
        this.closeModal(uppy);
      }
    });
    return uppy;
  }

  public createImageUploaderFB(path, onSuccess?): Uppy {
    const options = {uppySettings: {}, dashboardOptions: {}};
    options.uppySettings = {restrictions: {maxNumberOfFiles: 2, allowedFileTypes: ['image/jpeg', 'image/png'], maxFileSize: 1024 * 1024}};
    options.uppySettings['onBeforeFileAdded'] = () => true;
    options.dashboardOptions = {note: this.common.utils.i18n('upload.note.img')};
    const uppy = this.createCustomUploader(options, (result) => {
      if (result && result.successful && result.successful.length) {
        Promise.all(result.successful.map(value => {
          return new Promise((resolve, reject) => {
            const file = value.data;
            const metaData = {
              contentType: file.type
            };
            const refId = path + '/' + file.name;
            const uploadTask = this.afDB.database.app.storage().ref(refId).put(file, metaData);
            uploadTask.then(snapshot => {
              snapshot.ref.getDownloadURL().then(downloadUrl => {
                resolve(downloadUrl);
              });
            }).catch(reject);
          });
        })).then(res => {
          this.common.log.debug('downloadUrls', res);
          if (res && onSuccess) {
            onSuccess(res);
          }
          this.closeModal(uppy);
        }).catch((e) => {
          this.common.log.error(e);
          this.closeModal(uppy);
        });
      } else {
        this.closeModal(uppy);
      }
    });
    return uppy;
  }
}

export class MemoryStorage extends UIPlugin {
  title = '';
  _uploadFile:  any;

  constructor(uppy, opts) {
    super(uppy, opts);
    this.type = 'uploader';
    this.id = 'MemoryStorage';
    this.title = 'Memory Storage';
    this._uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(fileIds) {
    return Promise.all(
      fileIds.map(id => {
        return new Promise(async (resolve, reject) => {
          const file = this.uppy.getFile(id);
          // @ts-ignore
          this.uppy.emit('upload-started', file);
          const vm = this;
          const reader: FileReader = new FileReader();
          reader.onloadend = function(e) {
            const f = reader.result;
            if (f) {
              // @ts-ignore
              vm.uppy.emit(
                'upload-success',
                file,
                f
              );
              resolve(null);
            } else {
              // @ts-ignore
              vm.uppy.emit('upload-error', file, 'Error upload file');
              reject('Error upload file');
            }
          };

          let blob: Blob;
          if (file.source === 'Unsplash') {
            /** replace to improve resolution */
              const response = await fetch((file.data as any).icon.replace('w=200', 'w=1000'));
              blob = await response.blob();
          } else blob = file.data;
          reader.onerror = function(error) {
            // @ts-ignore
            vm.uppy.emit('upload-error', file, error);
            reject(error);
          };
          reader.readAsDataURL(blob);
        });
      })
    );
  }

  install() {
    this.uppy.addUploader(this._uploadFile);
  }

  uninstall() {
    this.uppy.removeUploader(this._uploadFile);
  }
}

export class LinkStorage extends UIPlugin {
  title = '';
  _uploadFile:  any;

  constructor(uppy, opts) {
    super(uppy, opts);
    this.type = 'uploader';
    this.id = 'LinkStorage';
    this.title = 'Link Storage';
    this._uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(fileIds) {
    return Promise.all(
      fileIds.map(id => {
        return new Promise((resolve, reject) => {
          const file = this.uppy.getFile(id);
          // @ts-ignore
          this.uppy.emit('upload-started', file);
          // @ts-ignore
          this.uppy.emit(
            'upload-success',
            file,
            file.data
          );
          resolve(null);
        });
      })
    );
  }

  install() {
    this.uppy.addUploader(this._uploadFile);
  }

  uninstall() {
    this.uppy.removeUploader(this._uploadFile);
  }
}

export class FirebaseStorage extends UIPlugin {
  title = '';
  storageRef: any;
  _uploadFile:  any;

  constructor(uppy, opts) {
    super(uppy, opts);
    if (!opts.storageRef) {
      throw Error(
        'Please provide the root storageRef to be used as option `storageRef`. ' +
        'See https://firebase.google.com/docs/storage/web/upload-files'
      );
    }
    this.type = 'uploader';
    this.id = 'FirebaseCloudStorage';
    this.title = 'Firebase Cloud Storage';
    this.storageRef = opts.storageRef;
    this._uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(fileIds) {
    return Promise.all(
      fileIds.map(id => {
        return new Promise((resolve, reject) => {
          const file = this.uppy.getFile(id);
          const refId = file.name;
          const fileRef = this.storageRef.child(refId);
          const metaData = {
            contentType: file.type
          };
          // @ts-ignore
          this.uppy.emit('upload-started', file);
          const uploadTask = fileRef.put(file.data, metaData);
          uploadTask.on(
            'state_changed',
            snapshot => {
              const progressInfo = {
                uploader: this,
                bytesUploaded: snapshot.bytesTransferred,
                bytesTotal: snapshot.totalBytes
              };
              // @ts-ignore
              this.uppy.emit('upload-progress', file, progressInfo);
            },
            error => {
              // @ts-ignore
              this.uppy.emit('upload-error', file, error);
              reject(error);
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then(downloadUrl => {
                const f = this.uppy.getFile(id);
                // @ts-ignore
                f.downloadUrl = downloadUrl;
                // @ts-ignore
                this.uppy.emit(
                  'upload-success',
                  f,
                  uploadTask.snapshot,
                  downloadUrl
                );
                resolve(null);
              });
            }
          );
        });
      })
    );
  }

  install() {
    this.uppy.addUploader(this._uploadFile);
  }

  uninstall() {
    this.uppy.removeUploader(this._uploadFile);
  }
}

export enum UPLOAD_TYPE {
  IMAGE = 'image',
  SINGLE_IMAGE = 'single_image',
  PDF = 'pdf',
  PNG = 'png',
  XML = 'xml',
  PECHA_KUCHA = 'pechakucha',
  ANY_DOCUMENT = 'any_document',
  ZIF = 'zif',
  AUDIO = 'audio'
}
