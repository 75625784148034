import {EventQuestion, ICheckFeedback} from '../../../../../model/EventQuestion';
import {FONT_SIZE, FONT_SIZE_VALUES} from '../../../../../components/text-editor/text-editor-config/config';
import {ICustomMenuAction} from '../../../shared/container-interface';

export const CHART_HEIGHT = 15;
export const CHART_POINT_RADIUS = 5;
export const SHORT_CHART_SMILE_COUNT_LESS = 4;
export const FONT_SIZE_CSS_PROPERTY = '--answer-caption__font-size';

export enum QUIZ_EDITOR_ACTIONS {
  SHOW_USER_MODE = 'show-user-mode'
}

export const QUIZ_PREVIEW_MENU: ICustomMenuAction[] = [{
  title: 'content.container.quiz.switch.between.presenter.attendee.mode',
  action: QUIZ_EDITOR_ACTIONS.SHOW_USER_MODE,
  icon: 'supervisor_account',
  tablerIcon: 'users'
}];

export interface IQuizQuestionMap {[id: string]: EventQuestion; }

export enum FEEDBACK_DIALOG_TYPE {
  TRY_AGAIN,
  CONTINUE
}

export const WORD_CLOUD_TEMPLATE_PREFIX = 'word_cloud_template_';

export enum QUESTION_INSIDE_COLLECTIONS {
  ANSWERS = 'answers',
  ANSWERS_SUMMARY = 'quiz_answers_summary'
}

export const QUIZ_QUESTION_INSIDE_COLLECTIONS: QUESTION_INSIDE_COLLECTIONS[] = [
  QUESTION_INSIDE_COLLECTIONS.ANSWERS,
  QUESTION_INSIDE_COLLECTIONS.ANSWERS_SUMMARY
];

export interface IQuizUserAnswers {
  [userId: string]: any;
}

export interface IQuizContentAnswers {
  [questionId: string]: IQuizUserAnswers;
}

export interface IQuizContentAnswersExtended {
  answers: IQuizContentAnswers;
  lastChange?: IQuizContentAnswers;
  sendTime?: IQuizContentAnswers;
  properties?: IQuizContentAnswers;
  users?: IQuizContentAnswers;
}

export interface IQuestionDirectFeedback {
  [questionId: string]: {
    result: ICheckFeedback;
    answersJSON: string;
    isFeedbackShowed: boolean;
  };
}

export interface IAnswerRow {
  answerMatching: string;
  answerMatchingId: string;
}

export interface IAnswer {
  answer?: string;
  id: string;
  orderIndex: number;
  answersMatching?: IAnswerRow[];
  correctAnswer?: boolean;
  progress?: string;
  unprogress?: string;
  count?: number;
  check?: any;
}

export interface IMatching {
  id: string;
  orderIndex: number;
  answerMatching?: string;
}

export enum ANONYMOUS_ANSWERS_MODE {
  NON_ANONYMOUS = 'non_anonymous',
  HIDE_NAME_IN_RESULT = 'hide_name_in_result',
  ANONYMOUS = 'anonymous'
}

export const QUIZ_FONT_SIZE = {...FONT_SIZE};
export const QUIZ_FONT_SIZE_VALUES = {...FONT_SIZE_VALUES};

export interface IQuizOptions {
  fontSize: string;
}

export class Quiz {
  questions: IQuizQuestionMap = {};
  notes = {};
  acceptAnswers = true;
  anonymousAnswersMode: ANONYMOUS_ANSWERS_MODE = ANONYMOUS_ANSWERS_MODE.NON_ANONYMOUS;
  showResult: boolean;
  anyUserCanShowResult: boolean;
  options: IQuizOptions = {
    fontSize: QUIZ_FONT_SIZE_VALUES[QUIZ_FONT_SIZE.NORMAL]
  };

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    if (obj.hasOwnProperty('questions')) {
      this.questions = Object.values(obj.questions || {}).reduce((acc, item: EventQuestion) => {
        acc[item.id] = new EventQuestion(item);
        return acc;
      }, {}) as IQuizQuestionMap;
    }
    if (obj.hasOwnProperty('notes')) {
      this.notes = obj.notes;
    }
    if (obj.hasOwnProperty('acceptAnswers')) {
      this.acceptAnswers = obj.acceptAnswers;
    }
    if (obj.hasOwnProperty('anonymousAnswers') && !obj.hasOwnProperty('anonymousAnswersMode')) {
      this.anonymousAnswersMode = obj.anonymousAnswers ? ANONYMOUS_ANSWERS_MODE.ANONYMOUS : ANONYMOUS_ANSWERS_MODE.NON_ANONYMOUS;
    }
    if (obj.hasOwnProperty('anonymousAnswersMode')) {
      this.anonymousAnswersMode = obj.anonymousAnswersMode;
    }
    if (obj.hasOwnProperty('showResult')) {
      this.showResult = obj.showResult;
    }
    if (obj.hasOwnProperty('anyUserCanShowResult')) {
      this.anyUserCanShowResult = obj.anyUserCanShowResult;
    }
    if (obj.hasOwnProperty('options')) {
      this.options = obj.options;
    }
  }

  isAnonymousAnswers() {
    return this.anonymousAnswersMode === ANONYMOUS_ANSWERS_MODE.ANONYMOUS;
  }

  isHideResultNames() {
    return this.anonymousAnswersMode === ANONYMOUS_ANSWERS_MODE.HIDE_NAME_IN_RESULT;
  }
  /**
   * Get questions with property "optional" = false
   */
  getStrictQuestions() {
    const result = {};
    if (this.questions) {
      for (const qKey of Object.keys(this.questions)) {
        const qObj: EventQuestion = this.questions[qKey];
        if (!qObj.optional) {
          result[qKey] = qObj;
        }
      }
    }
    return result;
  }

  toObject() {
    const checkUnsupportedValue = (object) => {
      Object.keys(object)
        .forEach(key => {
          if ((object[key] === undefined || object[key] === null || typeof object[key] === 'function')) {
            delete object[key];
          } else if (typeof object[key] === 'object') {
            if (typeof object[key]['toObject'] !== 'undefined') {
              object[key] = object[key].toObject();
            } else {
              checkUnsupportedValue(object[key]);
            }
          }
        });
    };
    const obj = {...this};
    checkUnsupportedValue(obj);
    return obj;
  }

}

export class ExtQuiz extends Quiz {
  id: string; // container id
  contentId: string; // ContentContainer id
  parentId: string; // section id
  title: string; // default use ContentContainer title
  constructor(obj) {
    super(obj);
    if (!obj.id || !obj.contentId || !obj.parentId) {
      throw new Error('required field is null');
    }
    this.id = obj.id;
    this.contentId = obj.contentId;
    this.parentId = obj.parentId;
    this.title = obj.title?.length ? obj.title : 'common.no.title';
  }
}
