import { QuizQuestionSheetComponent } from "../../../quiz-question-sheet/quiz-question-sheet.component";
import { QUIZ_VIEW_MODE } from "../../../quiz.service";

export const textBalloonsListView = (quiz: QuizQuestionSheetComponent) => {
  quiz.quizService.setQuestionViewMode(quiz.currentQKey$.getValue(), QUIZ_VIEW_MODE.LIST_VIEW);
  return;
};

export const textBalloonsCloudView = (quiz: QuizQuestionSheetComponent) => {
  quiz.quizService.setQuestionViewMode(quiz.currentQKey$.getValue(), QUIZ_VIEW_MODE.CLOUD_VIEW);
  return;
};
