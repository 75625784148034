import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {AnalyticsService} from '../services/analytics.service';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Router} from '@angular/router';
import {UtilsService} from './utils.service';
import {LoginService} from '../login/login.service';
import {CommonService} from './common.service';
import {firstValueFrom} from 'rxjs';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  exceptionList: {timestamp: number, md5: string }[] = [];


  constructor(private injector: Injector) {
  }

  handleError(error: any): void {
    const common = this.injector.get(CommonService);
    const loginService = this.injector.get(LoginService);
    const analytics = this.injector.get(AnalyticsService);
    const aff = this.injector.get(AngularFireFunctions);
    const router = this.injector.get(Router);

    let message;
    let stackTrace;

    if (error instanceof HttpErrorResponse) {
      // Server Error
      message = this.getServerMessage(error);
      stackTrace = this.getServerStack(error);
    } else {
      // Client Error
      message = this.getClientMessage(error);
      stackTrace = this.getClientStack(error);
    }
    const md5 = UtilsService.md5(message + stackTrace);
    if (!this.exceptionList.some(it => it.md5 === md5 && it.timestamp  < (new Date().getTime() - 1000))) {
      const item = this.exceptionList.find(it => it.md5 === md5);
      if (item) {
        item.timestamp = new Date().getTime();
      } else {
        this.exceptionList.push({timestamp: new Date().getTime(), md5: md5});
      }
      analytics.logEvent('exception', {description: message, stackTrace: stackTrace, fatal: false});
      if (common.utils.getEnv().debug.crashReport) {
        const appClientId = loginService.clientConfig$.getValue() ? loginService.clientConfig$.getValue().id : null;
        const gt = aff.httpsCallable('postCrashReport');
        const extInfo = common.getSessionStorageDebugInfo(window.location.href);
        const obj: {
          key: string,
          appClientId: string,
          message: string,
          stackTrace: string,
          url: string,
          href: string,
          appVersion: string,
          platform: string,
          userAgent: string,
          debugMessage: string
        } = {
          key: 'JzimQq9kJPGYjTwV4oyJUxPB0dfIo4lV',
          appClientId: appClientId,
          message: message,
          stackTrace: stackTrace,
          url: router.url,
          href: window.location.href,
          appVersion: common.utils.getEnv().version,
          platform: window.navigator['platform'],
          userAgent: window.navigator['userAgent'],
          debugMessage: extInfo
        };
        firstValueFrom(gt(obj));
      }
    }

    if (common.getEnv().production && error.toString) {
      throw error.toString();
    }
    throw error;
  }

  getClientMessage(error: Error | string | null): string {
    if (!navigator.onLine) {
      return 'No Internet Connection';
    }
    if (!error) {
      return null;
    }
    if (typeof error === 'string') {
      return error;
    }
    return error.message ? error.message : error.toString();
  }

  getClientStack(error: Error | string | null): string {
    if (!error || typeof error === 'string') {
      return null;
    }
    return error.stack;
  }

  getServerMessage(error: HttpErrorResponse): string {
    return error.message;
  }

  getServerStack(error: HttpErrorResponse): string {
    // handle stack trace
    return 'stack';
  }
}
