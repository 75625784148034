import { Injectable } from '@angular/core';
import {StorageApiService} from './storage-api.service';

@Injectable({
  providedIn: 'root'
})
export class StorageDataService {

  constructor(private storageApiService: StorageApiService) { }

  uploadImageByPath(path: string, base64: string) {
    return this.storageApiService.uploadImageByPath(path, base64);
  }

  uploadAnyImageToStorage(path: string, imageName: string, base64: string, contentType: string = 'image/png') {
    return this.storageApiService.uploadAnyImageToStorage(path, imageName, base64, contentType);
  }

  uploadAnyImageToStorageClient(clientId: string, path: string, imageName: string, base64: string, contentType: string = 'image/png') {
    return this.storageApiService.uploadAnyImageToStorageClient(clientId, path, imageName, base64, contentType);
  }

  uploadAnyObjectToStorageByDataPath(path: string, imageName: string, base64: string, contentType: string = 'image/png') {
    return this.storageApiService.uploadAnyObjectToStorageByDataPath(path, imageName, base64, contentType);
  }

  deleteObjectFromStorage(path: string) {
    return this.storageApiService.deleteObjectFromStorage(path);
  }

  deleteObjectFromClientStorage(path: string, id: string) {
    return this.storageApiService.deleteObjectFromClientStorage(`${path}/${id}`);
  }

  /**
   * delete from storage object with id by path;
   * @param path - path to itemName;
   * @param id - object id
   */
  deleteObjectFromStorageByDataPath(path: string, id: string) {
    return this.storageApiService.deleteObjectFromStorageByDataPath(`${path}/${id}`);
  }

  copyStorageDataObject(pathFrom: string, pathTo: string) {
    return this.storageApiService.copyStorageDataObject(pathFrom, pathTo);
  }


  getStorageDataURL(path: string): Promise<string> {
    return this.storageApiService.getStorageDataURL(path);
  }
}
