import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import firebase from 'firebase/compat/app';
import {LoggerService} from './logger.service';
import {TranslateService} from '@ngx-translate/core';
import {Md5} from 'ts-md5';
import {
  Constants,
  CONTENT_FROM_AUDIENCE_MODE,
  DATE_FORMAT,
  FIXED_SECTION_TYPE,
  ILanguage,
  ILanguageParams,
  TCaption,
  VIDEO_SITE_RECORDED_STREAMING_TYPES
} from './constants';
import {DatePipe} from '@angular/common';
import {AbstractContent} from '../model/content/AbstractContent';
import {AppUser} from '../model/AppUser';
import {SectionContent} from '../model/content/SectionContent';
import {ExtEvent} from '../model/ExtEvent';
import {clone, isEmpty, isEqual, merge, union} from 'lodash';
import {TimerService} from './timer.service';
import {AutopilotService} from '../services/autopilot.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Subject} from 'rxjs';
import {EventQuestion} from '../model/EventQuestion';
import {SafePipe} from '../pipes/safe.pipe';
import * as DOMPurify from 'dompurify';
import {SectionTimeline} from '../model/content/SectionTimeline';
import {LANGUAGE} from './language-constants';
import {User} from '@ninescopesoft/core';

type Units =
  'millisecond' | 'ms' |
  'second' | 'sec' | 's' |
  'minute' | 'min' | 'm' |
  'hour' | 'hr' | 'h' |
  'day' | 'd' |
  'week' | 'wk' | 'w' |
  'month' | 'mo' |
  'year' | 'yr' | 'y';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  fullDic = [
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
  ];
  digDic = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  closeCommonModalDialog = new Subject<boolean>();

  public static getDefaultProviderId() {
    const providers = environment.providers;
    // return providers.find(p => p.value === environment.defaultProvider);
    const provider = providers.find(p => p.value === environment.defaultProvider);
    return provider ? provider.provider : 'gapi';
  }

  public static md5(value: string): string {
    return value && (typeof value === 'string') ? Md5.hashStr(value.trim()).toString() : '';
  }

  public static md5u(value: AppUser): string {
    return value && value.userKey ? Md5.hashStr(value.userKey.trim()).toString() : '';
  }

  public static hash(string, digits) {
    digits = digits - 1 || 6;
    const m = Math.pow(10, digits + 1) - 1;
    const phi = Math.pow(10, digits) / 2 - 1;
    let n = 0;
    for (let i = 0; i < string.length; i++) {
      n = (n + phi * string.charCodeAt(i)) % m;
    }
    return n.toString();
  }

  public static getEmbeddedVideoUrl(url: string, permitRocheVideo = true): string {
    const setVideoId = (link: string, id: string) => link.replace(/(\{\{\w+}})/, id);
    if (!url) {
      return null;
    }
    for (const site of Object.keys(Constants.VIDEO_SITE_TEMPLATES)) {
      for (const template of Constants.VIDEO_SITE_TEMPLATES[site].urlTemplate) {
        const mr = url.match(template);
        if (mr && site === 'ROCHE' && permitRocheVideo) {
          const spl = url.split('=');
          for (const s of spl) {
            if (s.match(template)) {
              const link = s.split('"');
              for (const sl of link) {
                if (sl.match(template)) {
                  return sl;
                }
              }
            }
          }
        } else if (mr && site === 'KALTURA') {
          return url;
        } else if (mr) {
          return setVideoId(Constants.VIDEO_SITE_TEMPLATES[site].urlPlayer, mr[mr.length - 1]);
        }
      }
    }
    return null;
  }

  public static getTranslateToLanguage(object: TCaption, languageParams: ILanguageParams, emptyEqualsNull = false): string {
    const otherSingleLanguage = (obj) => Object.keys(obj || {}).length === 1 ? obj[Object.keys(obj)[0]] : null;
    if (!object) {
      return null;
    }
    if (!object || typeof object === 'string') {
      return object as string;
    } else {
      return languageParams.usedMultilingualContent ?
        (!emptyEqualsNull ?
          (object[languageParams.currentLanguage] ??
            (object[languageParams.defaultLanguage] ??
              (object[Constants.TIMELINE_DEFAULT_LANGUAGE] ?? object[LANGUAGE.EN]))) :
          (!object[languageParams.currentLanguage] ?
            object[languageParams.defaultLanguage] : object[languageParams.currentLanguage])) :
        (object[languageParams.defaultLanguage] ??
          (object[Constants.TIMELINE_DEFAULT_LANGUAGE] ?? otherSingleLanguage(object)));
    }
  }

  public static getTranslateToLanguageForAI(object: TCaption, languageParams: ILanguageParams, emptyEqualsNull = false): {text, lang} {
    const text = this.getTranslateToLanguage(object, languageParams, emptyEqualsNull);
    let lang;
    if (!object || typeof object === 'string') {
      lang = languageParams?.currentLanguage ?? (languageParams?.defaultLanguage ?? Constants.TIMELINE_DEFAULT_LANGUAGE);
    } else {
      lang = Object.keys(object).find(l => object[l] === text);
    }
    return {text, lang};
  }

  /**
   * @param object any object with interface with field name 'lang'
   * @param languageParams
   */
  public static getTranslateToLanguageFromArray(object: {lang}[], languageParams: ILanguageParams) {
    const otherSingleLanguage = (obj: {lang}[]) => {
      const langs = obj.reduce((acc, it) => {
        if (!acc.includes(it.lang)) {
          acc.push(it.lang);
        }
        return acc;
      }, []);
      return langs.length === 1 ? obj.filter(p => p.lang === langs[0]) : [];
    };

    if (!languageParams.usedMultilingualContent) {
      const simpleList = (object || []).filter(p => !p.lang);
      const defLangList = (object || []).filter(p => p.lang === languageParams.defaultLanguage);
      const mainLangList = (object || []).filter(p => p.lang === Constants.TIMELINE_DEFAULT_LANGUAGE);
      const otherLanguage = otherSingleLanguage(object ?? []);
      if (!isEmpty(simpleList) && !isEmpty(defLangList)) {
        return union(simpleList, defLangList);
      } else if (!isEmpty(simpleList)) {
        return simpleList;
      } else if (!isEmpty(defLangList)) {
        return defLangList;
      } else if (!isEmpty(mainLangList)) {
        return mainLangList;
      } else if (!isEmpty(otherLanguage)) {
        return otherLanguage;
      }
      return [];
    } else {
      const simpleList = (object || []).filter(p => !p.lang);
      const langList = (object || []).filter(p => p.lang === languageParams.currentLanguage);
      const defLangList = (object || []).filter(p => p.lang === languageParams.defaultLanguage);
      const mainLangList = (object || []).filter(p => p.lang === Constants.TIMELINE_DEFAULT_LANGUAGE);
      if (!isEmpty(langList) && !isEmpty(simpleList) && languageParams.currentLanguage === languageParams.defaultLanguage) {
        return union(langList, simpleList);
      } else if (!isEmpty(langList)) {
        return langList;
      } else if (!isEmpty(defLangList)) {
        return defLangList;
      } else if (!isEmpty(simpleList)) {
        return simpleList;
      } else if (!isEmpty(mainLangList)) {
        return mainLangList;
      }
      return [];
    }
  }

  public static getByLanguage(object, fieldName, languageParams: ILanguageParams, emptyEqualsNull = false) {
    const otherSingleLanguage = (obj) => Object.keys(obj || {}).length === 1 ? obj[Object.keys(obj)[0]] : null;
    if (!object) {
      return null;
    }
    if (!object[fieldName] || typeof object[fieldName] === 'string') {
      return object[fieldName] as string;
    } else {
      return languageParams.usedMultilingualContent ?
        (!emptyEqualsNull ?
          (object[fieldName][languageParams.currentLanguage] ??
            (object[fieldName][languageParams.defaultLanguage] ??
              (object[fieldName][Constants.TIMELINE_DEFAULT_LANGUAGE] ?? object[fieldName][LANGUAGE.EN]))) :
          (!object[fieldName][languageParams.currentLanguage] ?
            object[fieldName][languageParams.defaultLanguage] : object[fieldName][languageParams.currentLanguage])) :
        (object[fieldName][languageParams.defaultLanguage] ??
          (object[fieldName][Constants.TIMELINE_DEFAULT_LANGUAGE] ?? otherSingleLanguage(object[fieldName])));
    }
  }

  public static setByLanguage(object, fieldName: string, value: TCaption, languageParams: ILanguageParams) {
    const data = object[fieldName];
    if (languageParams?.usedMultilingualContent) {
      if (!data || typeof data === 'string') {
        object[fieldName] = (languageParams.defaultLanguage !== languageParams.currentLanguage ?
          {
            [languageParams.defaultLanguage]: data as string,
            [languageParams.currentLanguage]: value
          }
          :
          {
            [languageParams.defaultLanguage]: value as string
          }) as TCaption;
      } else {
        object[fieldName] = merge(data, {[languageParams.currentLanguage]: value});
      }
    } else {
      object[fieldName] = value;
    }
  }

  /**
   * used for difficult case when multilingual data stored in own field.
   * when server has class cast exception if field stored different types
   * @param object
   * @param fieldName
   * @param languageParams
   */
  public static getByMultilingual(object, fieldName, languageParams: ILanguageParams) {
    if (!languageParams.usedMultilingualContent) {
      return object[fieldName] as string;
    } else {
      const multilingualName = `multilingual${fieldName[0].toUpperCase()}${fieldName.substring(1)}`;
      const langObj = object[multilingualName];
      if (!langObj) {
        return object[fieldName] as string;
      } else {
        return langObj[languageParams.currentLanguage] ?? (langObj[languageParams.defaultLanguage] ?? object[fieldName]);
      }
    }
  }

  /**
   * used for difficult case when multilingual data stored in own field.
   * when server has class cast exception if field stored different types
   * @param object
   * @param fieldName
   * @param value
   * @param languageParams
   */
  public static setByMultilingual(object, fieldName: string, value: TCaption, languageParams: ILanguageParams) {
    const multilingualName = `multilingual${fieldName[0].toUpperCase()}${fieldName.substring(1)}`;
    if (!languageParams.usedMultilingualContent) {
      object[fieldName] = value;
      if (object[multilingualName]) {
        object[multilingualName] = {};
      }
    } else {
      if (!object[multilingualName]) {
        object[multilingualName] = (languageParams.defaultLanguage !== languageParams.currentLanguage ?
          {
            [languageParams.defaultLanguage]: object[fieldName],
            [languageParams.currentLanguage]: value
          }
          :
          {
            [languageParams.defaultLanguage]: value as string
          }) as TCaption;
      } else {
        object[multilingualName] = merge(object[multilingualName], {[languageParams.currentLanguage]: value});
      }
      object[fieldName] = object[multilingualName][languageParams.defaultLanguage];
    }
  }

  public static jsonSorted(obj) {
    const sortObjByKey = (value) => {
      if (value && typeof value === 'object') {
        if (Array.isArray(value)) {
          return value.map(sortObjByKey);
        } else {
          return Object.keys(value).sort().reduce(
            (o, key) => {
              const v = value[key];
              o[key] = sortObjByKey(v);
              return o;
            }, {});
        }
      } else {
        return value ?? null;
      }
    };
    return JSON.stringify(sortObjByKey(obj));
  }

  public static isObjectsEqual(a, b) {
    return isEqual(a ?? null, b ?? null);
  }

  public static createId(prefixLength: number = 3, short = false) {
    const dict = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let id = '';
    for (let i = 0; i < prefixLength; i++) {
      const rnd = Math.floor(Math.random() * 25);
      id += dict.charAt(rnd);
    }
    return `${id}${!short ? (new Date()).getTime().toString() : ''}`;
  }

  public static isDigitId(value) {
    return (value === null || value === undefined) ? false :
      (typeof value === 'number' || (typeof value === 'string' && value.match(/^[0-9]+$/)));
  }

  public static isStringId(value) {
    return (value === null || value === undefined) ? false :
      (typeof value === 'string' && !value.match(/^[0-9]+$/));
  }

  public static wrapObjectToProxy = (object, handler) => {
    for (const prop of Object.keys(object)) {
      if (typeof object[prop] === 'object' && object[prop] !== null) {
        object[prop] = UtilsService.wrapObjectToProxy(object[prop], handler);
      }
    }
    return new Proxy(object, handler);
  }

  public static appUserToUser(appUser: AppUser): User {
    const u = new User();
    u.id = appUser.userId;
    u.displayName = appUser.fullName;
    u.email = appUser.email;
    u.photoURL = appUser.picture;
    return u;
  }

  public static userToAppUser(user: User): Pick<AppUser, 'userId' | 'email' | 'fullName' | 'picture'> {
    const u = new AppUser();
    u.userId = user.id;
    u.fullName = user.displayName;
    u.email = user.email;
    u.picture = user.photoURL;
    return u;
  }

  constructor(public log: LoggerService
            , private translate: TranslateService
            , private timerService: TimerService
            , private sanitizer: DomSanitizer
            , private autopilotService: AutopilotService
            ) {
    const TEMPORARY_ATTRIBUTE = 'data-temp-href-target';

    DOMPurify.addHook('beforeSanitizeAttributes', function (node) {
      if (node.tagName === 'A') {
        if (!node.hasAttribute('target')) {
          node.setAttribute('target', '_self');
        }

        if (node.hasAttribute('target')) {
          node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute('target'));
        }
      }
    });

    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
        node.setAttribute('target', node.getAttribute(TEMPORARY_ATTRIBUTE));
        node.removeAttribute(TEMPORARY_ATTRIBUTE);
        if (node.getAttribute('target') === '_blank') {
          node.setAttribute('rel', 'noopener');
        }
      }
    });
  }

  public getEnv() {
    return environment;
  }

  public isRoche() {
    return environment.providers.find(it => it.value === 'roche');
  }

  public isP30() {
    return environment.appName === 'Timeline.click';
  }

  public getSystemCurrentTime() {
    return this.timerService.timerTime;
  }

  getCurrentTime() {
    return !this.getAutopilotEnabled() ? this.roundDateUpSeconds(this.getSystemCurrentTime()) : this.getSystemCurrentTime();
  }

  private getAutopilotEnabled() {
    return this.autopilotService.autopilotSettings && this.autopilotService.autopilotSettings.autopilotEnable;
  }
  // noinspection TsLint
  public validateEmail(email) {
    if (!email) {
      return false;
    }
    // For Unicode
    // var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  isValidUrl(url) {
    const objRE = /(^https?:\/\/)?[a-z0-9~_\-\.]+\.[a-z]{2,9}(\/|:|\?[!-~]*)?$/i;
    return objRE.test(url);
  }

  public prepareDbKey(key) {
    return key.replace(/[\.\#\$\[\]\/]/g, '_');
  }

  public extractDefaultDisplayNameFromEmail(email) {
    if (!email) {
      return null;
    }
    return this.capitalize(email.substring(0, email.indexOf('@')).replace(/\./g, ' ').replace(/_/g, ' '));
  }

  i18n(key: any, params?: any) {
    return key ? this.translate.instant(key, params) : key;
  }

  i18alt(key: any, returnValue?: any) {
    const tr = this.translate.instant(key, null);
    return returnValue && key === tr ? returnValue : tr;
  }

  public comparator(field, direction?: 'asc' | 'desc') {
    return function (a, b) {
      if (!a.hasOwnProperty(field) && b.hasOwnProperty(field)) {return 1; }
      if (a.hasOwnProperty(field) && !b.hasOwnProperty(field)) {return -1; }
      if (!direction || direction === 'asc') {
        if (a[field] < b[field]) {return -1; }
        if (a[field] > b[field]) {return 1; }
      } else if (direction === 'desc') {
        if (a[field] > b[field]) {return -1; }
        if (a[field] < b[field]) {return 1; }
      }
      return 0;
    };
  }

  public comparatorTheLargerOfTheTwoField(field1, field2, direction: 'asc' | 'desc') {
    return function (a, b) {
      const afield = +a[field1] > +a[field2] ? field1 : field2;
      const bfield = +b[field1] > +b[field2] ? field1 : field2;
      if (!direction || direction === 'asc') {
        if (a[afield] < b[bfield]) {return -1; }
        if (a[afield] > b[bfield]) {return 1; }
      } else if (direction === 'desc') {
        if (a[afield] > b[bfield]) {return -1; }
        if (a[afield] < b[bfield]) {return 1; }
      }
      return 0;
    };
  }

  public comparatorRelevancy(field, direction?: 'asc' | 'desc') {
    return function (a, b) {
      if (!a.hasOwnProperty(field) && b.hasOwnProperty(field)) {return 1; }
      if (a.hasOwnProperty(field) && !b.hasOwnProperty(field)) {return -1; }
      if (!direction || direction === 'asc') {
        if (a[field].relevancy() < b[field].relevancy()) {return -1; }
        if (a[field].relevancy() > b[field].relevancy()) {return 1; }
      } else if (direction === 'desc') {
        if (a[field].relevancy() > b[field].relevancy()) {return -1; }
        if (a[field].relevancy() < b[field].relevancy()) {return 1; }
      }
      return 0;
    };
  }

  public comparatorRelevancyExt(field, fieldExt, direction?: 'asc' | 'desc') {
    return function (a, b) {
      if (!a.hasOwnProperty(field) && b.hasOwnProperty(field)) {return 1; }
      if (a.hasOwnProperty(field) && !b.hasOwnProperty(field)) {return -1; }
      if (!direction || direction === 'asc') {
        if (a[field].relevancy() === b[field].relevancy()) {
          if (a[fieldExt] > b[fieldExt]) {return -1; }
          if (a[fieldExt] < b[fieldExt]) {return  1; }
        } else {
          if (a[field].relevancy() < b[field].relevancy()) {return -1; }
          if (a[field].relevancy() > b[field].relevancy()) {return 1; }
        }
      } else if (direction === 'desc') {
        if (a[field].relevancy() === b[field].relevancy()) {
          if (a[fieldExt] < b[fieldExt]) {return -1; }
          if (a[fieldExt] > b[fieldExt]) {return  1; }
        } else {
          if (a[field].relevancy() > b[field].relevancy()) {return -1; }
          if (a[field].relevancy() < b[field].relevancy()) {return 1; }
        }
      }
      return 0;
    };
  }

  public comparatorEmptyDown(field, direction?: 'asc' | 'desc') {
    return function (a, b) {
      if (!a.hasOwnProperty(field) && b.hasOwnProperty(field)) {return 1; }
      if (a.hasOwnProperty(field) && !b.hasOwnProperty(field)) {return -1; }
      if (!direction || direction === 'asc') {
        if (a[field] === '' && b[field] !== '') {return 1; }
        if (a[field] !== '' && b[field] === '') {return -1; }
        if (a[field] < b[field]) {return -1; }
        if (a[field] > b[field]) {return 1; }
      } else if (direction === 'desc') {
        if (a[field] !== '' && b[field] === '') {return -1; }
        if (a[field] === '' && b[field] !== '') {return 1; }
        if (a[field] > b[field]) {return -1; }
        if (a[field] < b[field]) {return 1; }
      }
      return 0;
    };
  }

  public comparatorNullToDown(field, direction?: 'asc' | 'desc') {
    return function (a, b) {
      if (!a.hasOwnProperty(field) && b.hasOwnProperty(field)) {return 1; }
      if (a.hasOwnProperty(field) && !b.hasOwnProperty(field)) {return -1; }
      if (!direction || direction === 'asc') {
        if (!!a[field] === false && !!b[field] !== false) {return 1; }
        if (!!a[field] !== false && !!b[field] === false) {return -1; }
        if (a[field] < b[field]) {return -1; }
        if (a[field] > b[field]) {return 1; }
      } else if (direction === 'desc') {
        if (!!a[field] !== false && !!b[field] === false) {return -1; }
        if (!!a[field] === false && !!b[field] !== false) {return 1; }
        if (a[field] > b[field]) {return -1; }
        if (a[field] < b[field]) {return 1; }
      }
      return 0;
    };
  }

  public comparatorExt(field, fieldExt, direction?) {
    return function (a, b) {
      if (!direction || direction === 'asc') {
        if (a[field] === b[field]) {
          if (a[fieldExt] < b[fieldExt]) {return -1; }
          if (a[fieldExt] > b[fieldExt]) {return  1; }
        } else {
          if (a[field] < b[field]) {return -1; }
          if (a[field] > b[field]) {return  1; }
        }
      } else if (direction === 'desc') {
        if (a[field] === b[field]) {
          if (a[fieldExt] > b[fieldExt]) {return -1; }
          if (a[fieldExt] < b[fieldExt]) {return  1; }
        } else {
          if (a[field] > b[field]) {return -1; }
          if (a[field] < b[field]) {return  1; }
        }
      }
      return 0;
    };
  }

  public sortNumeric(direction?) {
    if (!direction || direction === 'asc') {
      return (a, b) => a - b;
    } else {
      return (a, b) => b - b;
    }
  }

  public shuffleArray(array) {
    let i, j, temp;
    for (i = array.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  public isNumber(obj) {
    return (typeof obj.storypoint === 'number');
  }

  public indexOfProperty(myArray, value, fieldName?): number {
    if (!fieldName) {
      fieldName = 'id';
    }
    let res = -1;
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i][fieldName] === value) {
        res = i;
      }
    }
    return res;
  }

  public indexOfProperties(myArray, fields: {name: string, value: any}[]): number {
    let res = -1;
    for (let i = 0; i < myArray.length; i++) {
      let f = true;
      for (const obj of fields) {
        if (myArray[i].hasOwnProperty(obj.name) && myArray[i][obj.name] !== obj.value) {
          f = false;
        }
      }
      if (f) {
        res = i;
      }
    }
    return res;
  }

  simpleAnyToArray(value: {}): string[] {
    const ret: string[] = [];
    for (const k of Object.keys(value)) {
      ret.push(value[k]);
    }
    return ret;
  }

  simpleAnyToArrayByColumn(value: {}, column: string[]) {
    const ret = [];
    for (const col of column) {
      ret.push(value[col]);
    }
    return ret;
  }

  /**
   *
   * @param {{}} question with answers
   * @param qContentAnswers
   * @returns {{}} count by answers and 'scaleEnd' property value
   */
  groupByAnswers(question: EventQuestion, qContentAnswers: {}): {} {
    const groupAnswers: {} = {};
    if (isEmpty(question.items)) { return groupAnswers; }
    for (const a of question.items) {
      groupAnswers[a.id] = 0;
    }
    if (question.answers) {
      const answers = qContentAnswers ? qContentAnswers : {};
      for (const uId of Object.keys(answers)) {
        const userAnswers: string[] = answers[uId];
        for (const answer of userAnswers) {
          let cn: number = groupAnswers[answer];
          cn++;
          groupAnswers[answer] = cn;
        }
      }
      let maxAnswerCount = 0;
      for (const ak of Object.keys(groupAnswers)) {
        if (groupAnswers[ak] > maxAnswerCount) {
          maxAnswerCount = groupAnswers[ak];
        }
      }
      groupAnswers['scaleEnd'] = maxAnswerCount;
    }
    return groupAnswers;
  }

  /**
   *
   * @param {{}} question with answers
   * @param qContentAnswers
   * @returns {{}} count by answers and 'scaleEnd' property value
   */
  groupMatchingByAnswers(question: EventQuestion, qContentAnswers: {}): {} {
    const groupAnswers: {} = {};
    const correctPair: {} = {};
    if (isEmpty(question.items)) { return groupAnswers; }
    for (const a of question.items) {
      groupAnswers[a.id] = 0;
      correctPair[a.id + '-' + a.matching?.[0]] = 0;
    }
    const answers = qContentAnswers ? qContentAnswers : {};
    for (const uId of Object.keys(answers)) {
      const userAnswers: string[] = answers[uId];
      for (const answer of userAnswers) {
        if (Number.isInteger(correctPair[answer])) {
          let cn: number = correctPair[answer];
          cn++;
          correctPair[answer] = cn;
        }
      }
    }
    for (const pair of Object.keys(correctPair)) {
      const a = pair.split('-')[0];
      groupAnswers[a] = correctPair[pair];
    }
    let maxAnswerCount = 0;
    for (const ak of Object.keys(groupAnswers)) {
      if (groupAnswers[ak] > maxAnswerCount) {
        maxAnswerCount = groupAnswers[ak];
      }
    }
    groupAnswers['scaleEnd'] = maxAnswerCount;
    return groupAnswers;
  }

  getGroupedAnswers(answers: {}): {} {
    const groupAnswers: {} = clone(isEmpty(answers) ? {} : answers);
    let maxAnswerCount = 0;
    for (const ak of Object.keys(groupAnswers)) {
      if (groupAnswers[ak] > maxAnswerCount) {
        maxAnswerCount = groupAnswers[ak];
      }
    }
    groupAnswers['scaleEnd'] = maxAnswerCount;
    return groupAnswers;
  }

  sanitized(str: string): string {
    return str && str.length > 0 ? str.toString().replace(/[\<\>]+/g, '').replace(/[\w]+\:\/\//g, '') : '';
  }

  sanitizeTolerant(text: string, allowIframe = false) {
    if (!text) {
      return '';
    }
    if (allowIframe) {
      return DOMPurify.sanitize(text, {ADD_TAGS: ['iframe'], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling']});
    }
    return DOMPurify.sanitize(text);
  }

  urlParamEscape(value: any) {
    return encodeURIComponent(value ? value : '');
  }

  /**
   *  Return date format 'DD-MM-YYYY HH:mm'
   * @param value in milliseconds
   * @returns {string} string of format 'DD-MM-YYYY HH:mm' or empty string;
   */
  getDateStr(value): string {
    if (!value || value.length === 0) {
      return '';
    }
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(new Date(value), DATE_FORMAT.DD_MM_YYYY_HH_mm);
  }

  getDateStrFormat(value, format): string {
    if (!value || !format) {
      return '';
    }
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(value, format);
  }

  /**
   * @param value
   * @param {string} format (select from ready constant or other format string)
   * @param {boolean} wrapBrackets - if need wrap into round brackets
   * @returns {string} formatted string or empty string;
   * <p>
   * enum DATE_FORMAT
   * Format constant:
   * DD_MM_YYYY_HH_mm
   * DD_MM_YY_HH_mm
   * DD_MM_YYYY
   * DD_MM_YY
   * HH_mm</p>
   */
  formatDate(value, format: string, wrapBrackets = false): string {
    if (!value || value.length === 0) {
      return '';
    }
    const datePipe = new DatePipe('en-US');
    return (wrapBrackets ? '(' : '') + datePipe.transform(new Date(value), format) + (wrapBrackets ? ')' : '');
  }

  formatDateWeekDay(value): string {
    if (!value || value.length === 0) {
      return '';
    }
    const datePipe = new DatePipe('en-US');
    const cYear = (new Date()).getFullYear();
    const vYear = (new Date(value)).getFullYear();
    return cYear !== vYear ?
      datePipe.transform(new Date(value), DATE_FORMAT.WEEK_DAY_FULL_DD_MM_YY) :
      datePipe.transform(new Date(value), DATE_FORMAT.WEEK_DAY_FULL_DD_MM);
  }
  /**
   * Return string difference between two date.
   * @param value1 - Date or number
   * @param value2 - Date or number
   * @param {string} format - format string of return
   * @returns {string} formatted string or empty string
   * <p>
   * this.utils.<constant string>
   * Support format constant:
   * mm_ss
   * HH_mm_ss</p>
   */
  getDifferenceBetweenHoursMinutesSeconds(value1, value2, format: string): string {
    const vs = function (value: number): string {
      return (value >= 0 && value <= 9) ? '0' + value : '' + value;
    };
    if (!value1 || !value2) {return ''; }
    const date1 = new Date(value1);
    const date1Params = [date1.getHours(), date1.getMinutes(), date1.getSeconds()];
    const date2 = new Date(value2);
    const date2Params = [date2.getHours(), date2.getMinutes(), date2.getSeconds()];
    const diff = [Math.abs(date1Params[0] - date2Params[0]), Math.abs(59 - date2Params[1]),
      Math.abs(59 - date2Params[2])];
    switch (format) {
      case DATE_FORMAT.HH_mm_ss:
        return vs(diff[0]) + ':' + vs(diff[1]) + ':' + vs(diff[2]);
      case DATE_FORMAT.mm_ss:
        return vs(diff[1]) + ':' + vs(diff[2]);
      default:
        return '';
    }
  }

  /**
   * Create datetime from string of format 'hour:minute' base on today Year, Month and Day.
   * @param {string} hh_mm
   * @param {string} separator
   * @returns {number} datetime value in milliseconds
   */
  createDateFromHHmmStr(hh_mm: string, nullAsAny?): number|any {
    if (!hh_mm && nullAsAny) {return {}; }
    if (!hh_mm && !nullAsAny) {return null; }
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDate();
    let hh = 0;
    let mm = 0;
    if (hh_mm && hh_mm.length === 5) {
      const _hh_mm = hh_mm === Constants.TIME_NULL ? Constants.TIME_00_00 : hh_mm;
      hh = +_hh_mm.split(':')[0];
      mm = +_hh_mm.split(':')[1];
    }
    return new Date(year, month, day, hh, mm, 0).valueOf();
  }

  /**
   * Copy text to clipboard
   * @param text
   */
  copyToClipboard(text) {
    const input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
  }

  /**
   * Export data to CSV format and show download dialog.
   * @param {string[]} headNames
   * @param {string[]} fieldNames
   * @param {any[]} data
   * @param fileReportName
   */
  exportToCsvAndDownload(headNames: string[], fieldNames: string[], data: any [], fileReportName?: string) {
    const rowHead = headNames;
    const tableResult = data;
    let csvReport = rowHead.join(';') + '\n';
    for (const row of tableResult) {
      const line = this.simpleAnyToArrayByColumn(row,
        fieldNames).join(';') + '\n';
      csvReport = csvReport + line;
    }
    csvReport = '\uFEFF' + csvReport;
    const datePipe = new DatePipe('en-US');
    const reportDate = datePipe.transform(new Date().getTime(), 'dd-MM-y  HH:mm');
    const reportName = !fileReportName ? this.i18n('event.user.list') + ' (' + reportDate + ')' : fileReportName;
    this.downloadCsvReport(csvReport, reportName + '.csv', 'text/csv;encoding:utf-8');
  }

  downloadCsvReport(content, fileName, mimeType) {
    const a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';

    if (navigator['msSaveBlob']) { // IE10
      // @ts-ignore
      navigator.msSaveBlob(new Blob([content], {
        type: mimeType
      }), fileName);
    } else if (URL && 'download' in a) { // html5 A[download]
      a.href = URL.createObjectURL(new Blob([content], {
        type: mimeType
      }));
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
  }

  simpleDownloadFile(fileUrl: string) {
    const a = document.createElement('a');
    a.href = fileUrl;
    a.target = '_blank';
    a.download = this.formatDate(this.now(), DATE_FORMAT.DD_MM_YY_HH_mm_ss)
      .replace(/\./g, '_')
      .replace(/(,)/g, '_')
      .replace(/(:)/g, '_')
      .replace(/(\S)/g, '_') + '.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  createTextSplitLinks(text): string {
    if (!text) {
      return '';
    }
    const linkList = text.split('[');
    const containsBegin = text.indexOf('[') > -1;
    let result = '';
    for (let i = 0; i < linkList.length; i++) {
      if (linkList[i].length === 0) {continue; }
      const containsEnd = linkList[i].indexOf(']') > -1;
      linkList[i] = this.createTextLinks(
        this.createTextLinksM((containsBegin && containsEnd ? '[' : '') + linkList[i]));
      result = result + linkList[i];
    }
    return result;
  }

  parseInternalLink(url: string) {
    if (url && url.includes(window.location.host) && url.includes(window.location.pathname) && (url.includes('sid='))) {
      const path = url.split('?')[1];
      if (path) {
        const pathParams = path.split('&');
        const result = {};
        for (const params of pathParams) {
          const param = params.split('=');
          result[param[0]] = param[1];
        }
        return result;
      }
    }
    return null;
  }

  /**
   * @deprecate
   * Convert Plain Text format http://noname.tmp into Links
   * <p>todo: replace with
   * @link TextEditorService#parseTextLinks
   */
  createTextLinks(text, count?: (value: number) => void): string {
    let ind = 0;
    let replaceCount = 0;
    const result = (text || '').replace(
      /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
      function(match, space, url) {
        replaceCount++;
        let hyperlink = url;
        ind++;
        if (!hyperlink.match('^https?:\/\/')) {
          hyperlink = 'http://' + hyperlink;
        }
        const target = !url.includes(window.location.host) ? `target="_blank"` : '';
        const newUrl = `<a href="${hyperlink}" ${target}>${url}</a>`;
        return space + newUrl;
      }
    );
    if (typeof count === 'function') {
      count(replaceCount);
    }
    return result;
  }

  /**
   * @deprecate
   * Convert Plain Text format [Caption|http://noname.tmp] into Links
   * <p>todo: replace with
   * @link TextEditorService#parseTextLinks
   */
  createTextLinksM(text): string {
    let ind = 0;
    return (text || '').replace(
      /(\[)(.+)\|((https?\:\/\/)|(www\.))(\S+)(\])/gi,
      function(match, a1, caption, scheme, a4, a5, url) {
        let hyperlink = scheme + url;
        ind++;
        if (!hyperlink.match('^https?:\/\/')) {
          hyperlink = 'http://' + hyperlink;
        }
        return '<a href="' + hyperlink + '" target="_blank">' + caption + '</a>';
      }
    );
  }

  media(val: string, rval = 'unset'): string {
    if (document.body.clientWidth <= Constants.MEDIA_MAX_WIDTH) {
      return rval;
    } else {
      return val;
    }
  }

  convertTextToRichText(text: string, withoutBR?: boolean) {
    const br = (t: string): string => {
      return withoutBR ? t : this.convertNewLinesToBr(t);
    };
    return br(
      this.createTextLinks(
        this.createTextLinksM(
          this.createTextSplitLinks(
            this.sanitizeTolerant(text)))));
  }

  /**
   * Replace \n\r to <br>
   *
   * @param   {String} str  исходная строка
   * @returns {String}
   */
  convertNewLinesToBr(text): string {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  /**
   * Replace <br> to \n
   *
   * @param   {String} str  исходная строка
   * @returns {String}
   */
  convertBrToNewLines(text): string {
    return text ? text.replace(/(?:<br>)/g, '\n') : null;
  }

  getKeysByOrderField(object, orderFieldName = 'orderIndex', direction?): string[] {
    if (!object) {return null; }
    const baseOrder: any[] = [];
    const baseKeyList: any[] = Object.keys(object);
    baseKeyList.forEach( value => {
      baseOrder.push({key: value, orderIndex: object[value][orderFieldName] ? object[value][orderFieldName] : 0});
    });
    baseOrder.sort(this.comparator('orderIndex', direction));
    const keysByOrderField = [];
    baseOrder.forEach( value => {
      keysByOrderField.push(value.key);
    });
    return keysByOrderField;
  }

  reIndexObjectsOrderField(baseOrderKey: Array<any>, object, indexFieldName = 'orderIndex') {
    if (!object || !baseOrderKey || baseOrderKey.length === 0) {return; }
    baseOrderKey.forEach( (value, index) => {
      if (object[value]) {
        object[value][indexFieldName] = index;
      }
    });
  }

  /**
   * currentTimeMillis
   * @returns {number}
   */
  public now() {
    return this.getSystemCurrentTime();
  }

  /**
   * Return current date in milliseconds without time.
   * @returns {number}
   */
  public today() {
    const now = new Date(this.getSystemCurrentTime());
    return new Date(now.getFullYear(), now.getMonth(), now.getDate()).valueOf();
  }

  /**
   * Return current date in milliseconds with hour and minutes.
   * @returns {number}
   */
  public todayTime() {
    const now = new Date(this.getSystemCurrentTime());
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes()).valueOf();
  }

  public isToday(date) {
    const testDate = new Date(this.getSystemCurrentTime());
    const testDay = new Date(testDate.getFullYear(), testDate.getMonth(), testDate.getDate()).valueOf();
    const tDay = this.today();
    return testDay === tDay;
  }

  public getConnectInfo(navigator) {
    return {platform: navigator['platform'], userAgent: navigator['userAgent']};
  }

  /**
   *
   * @param {number} date
   * @returns {string} string of format: only time if today or short date time if not today,
   * or empty string if date is 0 or null, or undefined;
   * @param {boolean} wrapBrackets - if need wrap into round brackets
   */
  public captionDate(date: number, wrapBrackets = false): string {
    return (!date || date === 0) ? '' : (this.isToday(date) ?
      this.formatDate(date, DATE_FORMAT.HH_mm, wrapBrackets) : this.formatDate(date, DATE_FORMAT.DD_MM_YY_HH_mm, wrapBrackets));
  }

  /**
   * @param {string} userId
   * @param appUsers - object{key1: appUser1; key2: appUser2; ...} or array[appUser1, appUser2, ...]
   * @returns {string} link to user logo or default logo
   */
  getUserLogo(userId: string, appUsers: any): string {
    if (appUsers == null) { appUsers = []; }
    if (Array.isArray(appUsers)) {
      for (const user of appUsers) {
        if (user.userId === userId) {
          return user.picture;
        }
      }
      return Constants.DEFAULT_USER_LOGO;
    } else {
      return appUsers[userId] && appUsers[userId].picture ? appUsers[userId].picture : Constants.DEFAULT_USER_LOGO;
    }
  }

  /**
   * @param {string} userId
   * @param email
   * @param appUsers - object{key1: appUser1; key2: appUser2; ...} or array[appUser1, appUser2, ...]
   * @returns {string} link to user logo or default logo
   */
  getUserLogoExt(userId: string, email: string, appUsers: any): string {
    if (Array.isArray(appUsers)) {
      for (const user of appUsers) {
        if (user.userId === userId || user.email === email) {
          return user.picture;
        }
      }
      return Constants.DEFAULT_USER_LOGO;
    } else {
      return appUsers[userId] ? appUsers[userId].picture : Constants.DEFAULT_USER_LOGO;
    }
  }

  /**
   * @param {string} userId
   * @param appUsers - object{key1: appUser1; key2: appUser2; ...} or array[appUser1, appUser2, ...]
   * @returns {string} user full name or empty string
   */
  getUserName(userId: string, appUsers: any) {
    if (Array.isArray(appUsers)) {
      for (const user of appUsers) {
        if (user.userId === userId) {
          return user.fullName;
        }
      }
      return '';
    } else {
      return appUsers[userId] ? appUsers[userId].fullName : '';
    }
  }

  /**
   * @param {string} userId
   * @param appUsers - object{key1: appUser1; key2: appUser2; ...} or array[appUser1, appUser2, ...]
   * @returns {string} user full name or empty string
   */
  getUserDepartment(userId: string, appUsers: any) {
    if (Array.isArray(appUsers)) {
      for (const user of appUsers) {
        if (user.userId === userId) {
          return user.department;
        }
      }
      return '';
    } else {
      return appUsers[userId] ? appUsers[userId].department : '';
    }
  }

  /**
   * @param {string} userId
   * @param email
   * @param appUsers - object{key1: appUser1; key2: appUser2; ...} or array[appUser1, appUser2, ...]
   * @returns {string} user full name or empty string
   */
  getUserNameExt(userId: string, email: string, appUsers: any) {
    if (Array.isArray(appUsers)) {
      for (const user of appUsers) {
        if (user.userId === userId || user.email === email) {
          return user.fullName;
        }
      }
      return '';
    } else {
      return appUsers[userId] ? appUsers[userId].fullName : '';
    }
  }

  /**
   * ~~~~~~~~~~~~~~~~  Edit section/content dialog default fields  ~~~~~~~~~~~~~~~~~~~~~~~~
   */
  get isPublicField() {
    return { id: 'isPublic', type: 'select',
      options: [
        {value: true, name: this.i18n('common.public')},
        {value: false, name: this.i18n('common.private')}],
      placeholder: this.i18n('visibility.content')
    };
  }

  isPublicFieldDiasable(disable) {
    return { id: 'isPublic', type: 'select',
      options: [
        {value: true, name: this.i18n('common.public')},
        {value: false, name: this.i18n('common.private')}],
      placeholder: this.i18n('visibility.content'),
      disabled: disable
    };
  }

  get draftField() {
    return {id: 'draft', type: 'checkbox', placeholder: this.i18n('switch.draft'), hidden: false};
  }

  get canSelectAnonymouslyField() {
    return {id: 'anonymously', type: 'checkbox',
      placeholder: this.i18n('action.instantSettings.content.from.audience.create.anonymous'), hidden: true};
  }

  get anonymouslyField() {
    return {id: 'anonymously', type: 'checkbox',
      placeholder: this.i18n('action.instantSettings.content.from.audience.create.anonymous'), hidden: false};
  }

  get plannedTimeField() {
    return {id: 'plannedTime', type: 'time', required: false, placeholder: this.i18n('common.plannedtime'),
      hidden: false};
  }

  get titleField() {
    return {
      id: 'title', type: 'textarea', required: true, placeholder: this.i18n('common.title')
    };
  }

  get titleFieldNoRequired() {
    return {
      id: 'title', type: 'textarea', required: false, placeholder: this.i18n('common.title')
    };
  }

  get descriptionField() {
    return {
      id: 'description', type: 'textarea', required: true, placeholder: this.i18n('common.description')
    };
  }

  get descriptionFieldNoRequired() {
    return {
      id: 'description', type: 'textarea', required: false, placeholder: this.i18n('common.description')
    };
  }

  get subtitleFieldNoRequired() {
    return {
      id: 'description', type: 'textarea', required: false, placeholder: this.i18n('common.subtitle')
    };
  }

  get descriptionFieldExtCaption() {
    return {
      id: 'description', type: 'textarea', required: true, placeholder: this.i18n('common.description.ext')
    };
  }

  get locationField() {
    return {id: 'location', type: 'text', placeholder: this.i18n('common.location'), required: false};
  }

  /**
   * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   */

  /**
   * In childs of section find content befor current content, exlcude content of type private and draft.
   * @param {AbstractContent} currentContent
   * @param {AbstractContent[]} contentList
   * @returns {number} content id or -1 if not found
   */
  findAboveContent(currentContent: AbstractContent, contentList: AbstractContent[]): string {
    const listPublicContent = contentList.filter(function (item: AbstractContent) {
      if (item.isPublic && !item.draft) {
        return item;
      }
    });
    listPublicContent.sort(this.comparator(Constants.ORDERINDEX));
    const currenIndex = listPublicContent.findIndex(function (item: AbstractContent) {
      if (item.id === currentContent.id) {
        return true;
      }
    });
    return currenIndex > 0 ? listPublicContent[currenIndex - 1]['id'] : '';
  }

  /**
   * Check content id under active(feature) timeline.
   * @param {AbstractContent} content
   * @param featureLineId
   * @param {{}} planeListContent
   * @returns {boolean}
   */
  underFeatureLine(content: AbstractContent, featureLineId, planeListContent: {}) {
    if (featureLineId && planeListContent[featureLineId]) {
      const featureLineOrderIndex = planeListContent[featureLineId][Constants.ORDERINDEX];
      if (featureLineOrderIndex < content.orderIndex) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getUserOS(navigator) {
    const platform = navigator['platform'];
    const subplatform = navigator['userAgent'];
    if (platform === 'Win32') {
      return Constants.WINDOWS;
    }
    if (platform === 'Win64') {
      return Constants.WINDOWS;
    }
    if (platform === 'MacIntel') {
      return Constants.MAC_OS;
    }
    if (platform.match('Linux.')) {
      if (subplatform.match('.Android.')) {
        return Constants.ANDROID;
      } else {
        return Constants.LINUX;
      }
    }
    return platform;
  }

  getUserBrowser(navigator) {
    const ua = navigator.userAgent;
    let tem;
    let  M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem =  /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'msie';
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) { return tem.slice(1).join(' ').replace('OPR', 'Opera'); }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
    return M[0].toLowerCase();
  }

  activeSectionId(currentTimeLineId: number, timeLineWithParentList: {}) {
    if (currentTimeLineId) {
      const tlObj = timeLineWithParentList[currentTimeLineId];
      if (tlObj) {
        if (tlObj['type'] === Constants.CONTENT_TYPE_TIMELINE || tlObj['type'] === Constants.CONTENT_TYPE_SECTION) {
          return currentTimeLineId;
        } else {
          return tlObj['parentId'] ? tlObj['parentId'] : this.lastHeadSectionId(timeLineWithParentList);
        }
      } else {
        return this.lastHeadSectionId(timeLineWithParentList);
      }
    } else {
      return this.lastHeadSectionId(timeLineWithParentList);
    }
  }

  lastHeadSectionId(timeLineWithParentList: {}) {
    const headLines = this.objectValues(timeLineWithParentList).filter(function (item) {
      if (!item['parentId'] && (item['type'] === Constants.CONTENT_TYPE_TIMELINE || item['type'] === Constants.CONTENT_TYPE_SECTION)) {
        return item;
      }
    });
    headLines.sort(this.comparator(Constants.ORDERINDEX, 'desc'));
    if (headLines && headLines.length > 0) {
      return headLines[0]['id'];
    } else {
      return null;
    }
  }

  fontSizeScale(question: {}, media: boolean, fullscreen: boolean): string {
    const defValue = 2;
    let coeff = 0;
    if (question && question['items'] &&
        question['storypoint'] !== Constants.QTYPE_TEXT && question['storypoint'] !== Constants.QTYPE_WORD_CLOUD) {
      const count = question['items'].length;
      if (count <= 3) {
        coeff = media && !fullscreen ? 1.5 : 2;
      } else if (count <= 6) {
        coeff = media && !fullscreen ? 0 : 1;
      } else if (count <= 10) {
        if (media && !fullscreen) {coeff = -0.2; } else
        if (media && fullscreen)  {coeff = 0.5; } else
        if (!media && fullscreen) {coeff = 0.5; } else {coeff = 0; }
      } else if (count <= 12) {
        if (media && !fullscreen) {coeff = -0.3; } else
        if (media && fullscreen)  {coeff = -0.1; } else
        if (!media && fullscreen) {coeff = 0.5; } else {coeff = -0.2; }
      } else if (count > 12) {
        if (media && !fullscreen) {coeff = -0.3; } else
        if (media && fullscreen)  {coeff = -0.2; } else
        if (!media && fullscreen) {coeff = 0.5; } else {coeff = -0.5; }
      }
    }
    return '' + (defValue + coeff) + 'vh';
  }

  isClipboardUrl(url: string): boolean {
    if (url && url.length > 0 && url.indexOf('data:image/') > -1 && url.indexOf(';base64,') > -1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Check for url include 'firebasestorage.googleapis.com' and current eventId else this is
   * simple direct link.
   * @param url
   * @param eventId
   */
  isFBUrl(url: string, eventId): boolean {
    if (url && url.length > 0 &&
      (url.indexOf('firebasestorage.googleapis.com') > -1 ||
       url.indexOf('storage.googleapis.com') > -1) &&
        url.indexOf(eventId) > -1) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * Alternative Javascript "Object.values" because Internet Explorer not support it.
   * @param object
   */
  objectValues(object) {
    return object ? Object.keys(object).map(function (key) {
      return object[key];
    }) : [];

  }

  /**
   * Detect IE browser.
   */
  IE(): boolean {
    const ua = navigator.userAgent;
    if (ua.search(/Edge/) > -1) { return true; }
    if (ua.search(/MSIE/) > -1) {return true; }
    if (ua.search(/Trident/) > -1) {return true; }
    return false;
  }

  /**
   * Detect Firefox browser.
   */
  FF(): boolean {
    const ua = navigator.userAgent;
    if (ua.search(/Firefox/) > -1) { return true; }
    return false;
  }

  /**
   * Return 1 if element overflow his parent else return -1.
   * @param {Element} element
   */
  isOverflowed(element: Element) {
    if (element && element.parentElement) {
      if (element.scrollHeight > element.parentElement.scrollHeight) {
        return 1;
      } else
      if (element.scrollHeight < element.parentElement.scrollHeight) {
        return -1;
      }
    }
    return 0;
  }

  isSimpleUser(user: AppUser) {
    return !user.isAdmin && !user.isModerator && !user.isTrainer && !user.isSimpleRegistration;
  }

  eventFilterDisplayStatusList(simpleUser) {
    let displayList = [];
    if (simpleUser) {
      displayList = [Constants.EVENT_FILTER_STATUS_ALL_OPEN_STATES, Constants.EVENT_FILTER_STATUS_ALL_STATES,
        Constants.EVENT_FILTER_UPCOMING, Constants.EVENT_FILTER_STATUS_RUNNING,
        Constants.EVENT_FILTER_STATUS_PREPARATION, Constants.EVENT_FILTER_STATUS_WRAP_UP,
        Constants.EVENT_FILTER_PAST_EVENTS, Constants.EVENT_FILTER_RECENTLY_MODIFIED_BY_ME,
        Constants.EVENT_FILTER_STATUS_FINISHED, Constants.EVENT_FILTER_STATUS_PRIVATE, Constants.EVENT_FILTER_STATUS_PUBLIC];
    } else {
      displayList = [Constants.EVENT_FILTER_STATUS_ALL_OPEN_STATES, Constants.EVENT_FILTER_STATUS_ALL_STATES,
        Constants.EVENT_FILTER_UPCOMING, Constants.EVENT_FILTER_STATUS_RUNNING,
        Constants.EVENT_FILTER_STATUS_PREPARATION, Constants.EVENT_FILTER_STATUS_WRAP_UP,
        Constants.EVENT_FILTER_PAST_EVENTS, Constants.EVENT_FILTER_RECENTLY_MODIFIED_BY_ME,
        Constants.EVENT_FILTER_STATUS_FINISHED,
        Constants.EVENT_FILTER_STATUS_DRAFT,
        Constants.EVENT_FILTER_STATUS_PUBLIC,
        Constants.EVENT_FILTER_STATUS_PRIVATE,
        Constants.EVENT_FILTER_STATUS_PUBLIC_BY_LINK];
    }
    return displayList;
  }

  /**
   * parser params:
   *  s - status
   *  f - field
   * @param status
   */
  displayFilterStatusToSystemStatus(status): string[] {
    const statusList = {};
    statusList[Constants.EVENT_FILTER_STATUS_ALL_OPEN_STATES] = ['s|!=|' + Constants.EVENT_STATUS_FINISHED];
    statusList[Constants.EVENT_FILTER_STATUS_ALL_STATES] = ['*'];
    statusList[Constants.EVENT_FILTER_STATUS_PREPARATION] =
      [Constants.EVENT_STATUS_PREP_START_ON, Constants.EVENT_STATUS_PREP_OPENS_ON];
    statusList[Constants.EVENT_FILTER_STATUS_RUNNING] =
      [Constants.EVENT_STATUS_RUNNING, Constants.EVENT_STATUS_RUNNING_EVENT_FINISH_IN];
    statusList[Constants.EVENT_FILTER_STATUS_WRAP_UP] = ['s|=|' + Constants.EVENT_STATUS_WRAP_UP_UNTIL];
    statusList[Constants.EVENT_FILTER_STATUS_FINISHED] = ['s|=|' + Constants.EVENT_STATUS_FINISHED];
    statusList[Constants.EVENT_FILTER_STATUS_PRIVATE] = ['f|false|isPublic&f|true|published&f|!1|dopState'];
    statusList[Constants.EVENT_FILTER_STATUS_PUBLIC] = ['f|true|isPublic&f|!1|dopState'];
    statusList[Constants.EVENT_FILTER_STATUS_PUBLIC_BY_LINK] = ['f|true|isPublic&f|1|dopState'];
    statusList[Constants.EVENT_FILTER_STATUS_UNPUBLISHED] = ['f|false|published'];
    statusList[Constants.EVENT_FILTER_STATUS_PUBLISHED] = ['f|true|published'];
    statusList[Constants.EVENT_FILTER_STATUS_DRAFT] = ['f|false|published&f|false|isPublic'];
    statusList[Constants.EVENT_FILTER_UPCOMING] = ['s|in|' +
      Constants.EVENT_STATUS_EVENT_STARTS_IN + ',' + Constants.EVENT_STATUS_EVENT_STARTS_ON + '&f|in merge permitted invited|eventId'];
    statusList[Constants.EVENT_FILTER_PAST_EVENTS] = ['s|=|' + Constants.EVENT_STATUS_FINISHED + '&f|in permitted|eventId'];
    statusList[Constants.EVENT_FILTER_RECENTLY_MODIFIED_BY_ME] = ['f|in permitted|eventId'];
    return statusList[status];
  }

  eventFilterDisplayStatusNeedSortByStartDate(status) {
     return [Constants.EVENT_FILTER_UPCOMING, Constants.EVENT_FILTER_PAST_EVENTS].includes(status);
  }

  eventFilterDisplayStatusNeedCustomSort(status) {
    return [Constants.EVENT_FILTER_RECENTLY_MODIFIED_BY_ME].includes(status);
  }

  applyStatesFilterOnEvent = function(event: ExtEvent,
                                      invitedUserEvents: {[eventId: string]: any},
                                      permittedUserEvents: {[eventId: string]: any},
                                      filter: string[]) {
    const states = event.getEventStatus()[0];
    if (filter.length === 1 && filter[0] === '*') {
      return event;
    } else
    if (filter.length === 1) {
      const mainFilter = filter[0].split('&');
      const result: boolean[] = [];
      for (let i = 0; i < mainFilter.length; i++) {
        const sFilter = mainFilter[i];
        result.push(false);
        const filterParams = sFilter.split('|');
        const type = filterParams[0];
        const condition = filterParams[1];
        const value = filterParams[2];
        if (type === 's') {
          if (condition === '!=') {
            if (states !== value) {
              result[i] = true;
            }
          } else if (condition === '=') {
            if (states === value) {
              result[i] = true;
            }
          } else if (condition === 'in') {
            if (value.split(',').includes(states)) {
              result[i] = true;
            }
          }
        } else if (type === 'f') {
          if (condition === 'false') {
            if (!event[value]) {
              result[i] = true;
            }
          } else if (condition === 'true') {
            if (event[value]) {
              result[i] = true;
            }
          } else if (condition.startsWith('!') && event[value] !== +(condition.substr(1))) {
            result[i] = true;
          } else if (!condition.startsWith('!') && event[value] === +condition) {
            result[i] = true;
          } else if (condition.match(/(in)\s(permitted)/) &&
              !isEmpty(permittedUserEvents) && permittedUserEvents[event[value]]) {
            result[i] = true;
          } else if (condition.match(/(in)\s(merge)\s(permitted)\s(invited)/) &&
              !isEmpty(merge(permittedUserEvents, invitedUserEvents)) && (merge(permittedUserEvents, invitedUserEvents))[event[value]]) {
            result[i] = true;
          }
        }
      }
      if (result.every(elem => elem === true)) {
        return event;
      }
    } else
    if (filter.includes(states)) {
      return event;
    }
  };

  private eventFilterStatusSortByStartDateParams(status) {
    const sortParams = {};
    sortParams[Constants.EVENT_FILTER_UPCOMING] = 'asc';
    sortParams[Constants.EVENT_FILTER_PAST_EVENTS] = 'desc';
    return sortParams[status];
  }

  public eventFilterStatusSortByStartDate(events: Array<ExtEvent>, status) {
    const param = this.eventFilterStatusSortByStartDateParams(status);
    return events.sort(this.comparator('startDate', param));
  }

  private eventFilterStatusCustomSortParams(status) {
    const sortParams = {};
    sortParams[Constants.EVENT_FILTER_RECENTLY_MODIFIED_BY_ME] = 'desc';
    return sortParams[status];
  }

  public eventFilterStatusCustomSortByObjects(events: Array<ExtEvent>, status, sortByObjects) {
    const param = this.eventFilterStatusCustomSortParams(status);
    if (status === Constants.EVENT_FILTER_RECENTLY_MODIFIED_BY_ME) {
      return events.map(ev => new Object({
        event: ev,
        lastActivity: !isEmpty(sortByObjects) ? sortByObjects[ev.eventId] : undefined}))
        .sort(this.comparatorNullToDown('lastActivity', param))
        .map(obj => obj['event']);
    } else {
      return events;
    }
  }

  getSectionSpeakers(list: SectionContent[]) {
    if (!list) {return []; }
    const vm = this;
    const speakers = [];
    list.forEach(content => {
      if (content.users) {
        this.objectValues(content.users).forEach(user => {
          if (user.speaker) {
            speakers.push(user.userId);
          }
        });
      }
    });
    return speakers;
  }

  unionArrays(listArrayList: Array<any[]>): any[] {
    const result = [];
    listArrayList.forEach( list => {
      list.forEach( obj => {
        result.push(obj);
      });
    });
    return result;
  }

  getPlannedTime(section) {
    if (!section) {return ''; }
    const ret = this.formatDate(section.plannedTime, DATE_FORMAT.HH_mm);
    return ret === Constants.TIME_00_00 ? '' : ret;
  }

  getValueByIdOrEmail(object, id, email) {
    const emailAsId = email ? email.replace(/\./g, '_').replace('@', '_') : null;
    return object[id] ? object[id] : (object[emailAsId] ? object[emailAsId] : null);
  }

  emailToId(email) {
    return email ? email.replace(/\./g, '_').replace('@', '_') : null;
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  generateRandomString(length: number) {
    let randomValue = '';
    for (let i = 0; i < length; i++) {
      const randomInt = this.getRandomInt(0, this.fullDic.length - 1);
      randomValue += this.fullDic[randomInt];
    }
    return randomValue;
  }

  generateRandomNumberString(length: number) {
    let randomValue = '';
    for (let i = 0; i < length; i++) {
      const randomInt = this.getRandomInt(0, this.digDic.length - 1);
      randomValue += this.digDic[randomInt];
    }
    return randomValue;
  }

  unsubscribeAll(subscriptions: {}, subscriptionName?: string) {
    if (!subscriptions) {return; }
    if (subscriptionName) {
      if (subscriptions[subscriptionName]) {
        subscriptions[subscriptionName].unsubscribe();
      }
      subscriptions[subscriptionName] = null;
      delete subscriptions[subscriptionName];
      return;
    }
    for (const name of Object.keys(subscriptions)) {
      if (subscriptions[name]) {
        subscriptions[name].unsubscribe();
      }
      subscriptions[name] = null;
      delete subscriptions[name];
    }
  }

  public millisTo_HH_MM(duration: number): number[] {
    if (!duration) {
      return null;
    }
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    return [hours, minutes];
  }

  /**
   * milliseconds into word with count [n]years [n]months [n]days [n]hours [n]minutes
   * @param duration
   * @param outputZero if true, if value equal 0 return 0 else return empty string
   */
  public millisTo_YY_MM_DD_hh_mm(duration: number, outputZero = false, delimiter = ''): string {
    if (duration === null || duration === undefined) {
      return null;
    } else if (duration === 0) {
      return outputZero ? '0' : '';
    }
    const years = Math.floor(duration / 31536000000);
    const months = Math.floor((duration % 31536000000) / 2628000000);
    const days = Math.floor(((duration % 31536000000) % 2628000000) / 86400000);
    const hours = Math.floor(((((duration % 31536000000) % 2628000000) % 86400000) / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor(((((duration % 31536000000) % 2628000000) % 86400000) / (1000 * 60)) % 60);
    const result =
      (years >= 1 ? years + ' ' + (years === 1 ? (this.i18n('common.time.1.year') + delimiter) :
        (this.i18n('common.time.years') + delimiter)) : '') + ' ' +
      (months >= 1 ? months + ' ' + (months === 1 ? (this.i18n('common.time.1.month') + delimiter) :
        (this.i18n('common.time.months') + delimiter)) : '') + ' ' +
      (days >= 1 ? days + ' ' + (days === 1 ? (this.i18n('common.time.1.day') + delimiter) :
        (this.i18n('common.time.days') + delimiter)) : '') + ' ' +
      (hours >= 1 ? hours + ' ' + (hours === 1 ? (this.i18n('common.time.1.hour') + delimiter) :
        (this.i18n('common.time.hours') + delimiter)) : '') + ' ' +
      (minutes >= 1 ? minutes + ' ' + (minutes === 1 ? this.i18n('common.time.1.minute') : this.i18n('common.time.minutes')) : '');
    return result ? result.trim() : result;
  }

  /**
   * short mode milliseconds into word with count [n]Y [n]M [n]d [n]h [n]m
   * @param duration
   * @param outputZero if true, if value equal 0 return 0 else return empty string
   */
  public millisTo_YY_MM_DD_hh_mm_Short(duration: number, outputZero = false): string {
    if (duration === null || duration === undefined) {
      return '';
    } else if (duration === 0) {
      return outputZero ? '0' : '';
    }
    const years = Math.floor(duration / 31536000000);
    const months = Math.floor((duration % 31536000000) / 2628000000);
    const days = Math.floor(((duration % 31536000000) % 2628000000) / 86400000);
    const hours = Math.floor(((((duration % 31536000000) % 2628000000) % 86400000) / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor(((((duration % 31536000000) % 2628000000) % 86400000) / (1000 * 60)) % 60);
    const result = (years >= 1 ? years + ' ' + this.i18n('Y') + ' ' : '') +
      (months >= 1 ? months + ' ' + this.i18n('Mo') + ' ' : '') +
      (days >= 1 ? days + ' ' + this.i18n('d') + ' ' : '') +
      (hours >= 1 ? hours + ' ' + this.i18n('h') + ' ' : '') +
      (minutes >= 1 ? minutes + ' ' + this.i18n('m') : '');
    return result ? result.trim() : result;
  }

  /**
   * short mode milliseconds into word with count [n]Y [n]M [n]d [n]h [n]' [n]m
   * @param duration
   * @param outputZero if true, if value equal 0 return 0 else return empty string
   */
  public millisTo_YY_MM_DD_hh_mm_ss_Short(duration: number, outputZero = false): string {
    const get0d = (d: number) => d <= 9 ? '0' + d : '' + d;
    if (duration === null || duration === undefined) {
      return '';
    } else if (duration === 0) {
      return outputZero ? '0' : '';
    }
    const years = Math.floor(duration / 31536000000);
    const months = Math.floor((duration % 31536000000) / 2628000000);
    const days = Math.floor(((duration % 31536000000) % 2628000000) / 86400000);
    const hours = Math.floor(((((duration % 31536000000) % 2628000000) % 86400000) / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor(((((duration % 31536000000) % 2628000000) % 86400000) / (1000 * 60)) % 60);
    const seconds = Math.floor(((((duration % 31536000000) % 2628000000) % 86400000) / (1000)) % 60);
    return (years >= 1 ? years + ' ' + this.i18n('Y') : '') + ' ' +
      (months >= 1 ? months + ' ' + this.i18n('Mo') : '') + ' ' +
      (days >= 1 ? days + ' ' + this.i18n('d') : '') + ' ' +
      (hours >= 1 ? hours + ' ' + this.i18n('h') : '') + ' ' +
      (minutes >= 1 ? minutes + ' ' + this.i18n('m') : '') + ' ' +
      (seconds >= 0 ? get0d(seconds) + ' ' + this.i18n('s') : '');
  }

  /**
   * Compare two dates by year, month, day, hour, minutes.
   * @param time1
   * @param time2
   */
  compareDatesToMinutes(time1: number, time2: number) {
    if ((!time1 && !!time2) || (!!time1 && !time2)) {return false; }
    const cDate = new Date(time1);
    const tDate = new Date(time2);
    return (!time1 && !time2) || (cDate.getFullYear() === tDate.getFullYear() && cDate.getMonth() === tDate.getMonth() &&
      cDate.getDate() === tDate.getDate() && cDate.getHours() === tDate.getHours() && cDate.getMinutes() === tDate.getMinutes());
  }

  /**
   * Compare two dates by year, month, day.
   * @param time1
   * @param time2
   */
  equalDatesByDay(time1: number, time2: number) {
    if ((!time1 && !!time2) || (!!time1 && !time2)) {return false; }
    const cDate = new Date(time1);
    const tDate = new Date(time2);
    return (!time1 && !time2) || (cDate.getFullYear() === tDate.getFullYear() && cDate.getMonth() === tDate.getMonth() &&
      cDate.getDate() === tDate.getDate());
  }

  /**
   * Compare two dates by year, month, day.
   * @param time1
   * @param time2
   * return -1: time1 = null or time2 = null, or time1 and time2 = null
   *         0: time1 = time2
   *         1: time1 != time2
   */
  compareNotNullDatesDay(time1: number, time2: number): -1 | 0 | 1 {
    if ((!time1 && !!time2) || (!!time1 && !time2) || (!time1 && !time2)) {return -1; }
    const cDate = new Date(time1);
    const tDate = new Date(time2);
    return (cDate.getFullYear() === tDate.getFullYear() && cDate.getMonth() === tDate.getMonth() &&
      cDate.getDate() === tDate.getDate()) ? 0 : 1;
  }

  /**
   * Return fixed section title
   * @param section
   * @param contentFromAudienceMode
   * @param contentsCount
   * @param headTitle - true if show on contents list
   */
  getExtendedSectionTitle(section: SectionContent, contentFromAudienceMode: CONTENT_FROM_AUDIENCE_MODE, headTitle?: boolean) {
    if (!section) {
      return '';
    }
    if (section.fixedSectionType) {
      if (contentFromAudienceMode === CONTENT_FROM_AUDIENCE_MODE.EASY_QA) {
        if (section.fixedSectionType === FIXED_SECTION_TYPE.EASY_NEW) {
            return this.i18n('easy.section.new.contents.title.qa.0');
        } else
        if (section.fixedSectionType === FIXED_SECTION_TYPE.EASY_DONE) {
            return this.i18n('easy.section.down.contents.title.qa.0');
        } else
        if (section.fixedSectionType === FIXED_SECTION_TYPE.EASY_SUGGESTED) {
            return this.i18n('easy.section.suggested.contents.title.qa.0');
        }
      } else if (contentFromAudienceMode === CONTENT_FROM_AUDIENCE_MODE.EASY_CONTENT) {
        if (section.fixedSectionType === FIXED_SECTION_TYPE.EASY_NEW) {
            return this.i18n('easy.section.new.contents.title.collection.0');
        } else
        if (section.fixedSectionType === FIXED_SECTION_TYPE.EASY_DONE) {
            return this.i18n('easy.section.down.contents.title.collection.0');
        } else
        if (section.fixedSectionType === FIXED_SECTION_TYPE.EASY_SUGGESTED) {
            return this.i18n('easy.section.suggested.contents.title.collection.0');
        }
      }
    } else {
      return section.title ? section.title : '';
    }
  }

  getIdArray(obj): any[] {
    return obj ? Object.keys(obj) : [];
  }

  getArrayByObject(list: any[], idField: string): {} {
    if (isEmpty(list)) {
      return {};
    }
    return list.reduce((accum, item) => {
      const id = item[idField];
      accum[id] = item;
      return accum;
    }, {});
  }

  clearTimeInDate(value: number): number {
      const d = new Date(value);
      return new Date(d.getFullYear(), d.getMonth(), d.getDate()).getTime();
  }

  getVideoSiteAndVideoIdFromUrl(url: string, permitRocheVideo: boolean): {site, url} {
    const setVideoId = (link: string, id: string) => link.replace(/(\{\{\w+}})/, id);
    if (!url) {
      return null;
    }
    for (const site of Object.keys(Constants.VIDEO_SITE_TEMPLATES)) {
      for (const template of Constants.VIDEO_SITE_TEMPLATES[site].urlTemplate) {
        const mr = url.match(template);
        if (mr && site === 'ROCHE' && permitRocheVideo) {
          const spl = url.split('=');
          for (const s of spl) {
            if (s.match(template)) {
              const link = s.split('"');
              for (const sl of link) {
                if (sl.match(template)) {
                  return {site: site, url: sl};
                }
              }
            }
          }
        } else if (mr && site === 'KALTURA') {
          return {site: site, url: url};
        } else if (mr) {
          return {site: site, url: setVideoId(Constants.VIDEO_SITE_TEMPLATES[site].urlPlayer, mr[mr.length - 1])};
        }
      }
    }
    return null;
  }

  getSketchModelIdFromUrl(url: string): string {
    const setVideoId = (link: string, id: string) => link.replace(/(\{\{\w+}})/, id);
    if (!url) {
      return null;
    }
    for (const site of Object.keys(Constants.SKETCH_SITE_TEMPLATES)) {
      let templateIndex = -1;
      for (const template of Constants.SKETCH_SITE_TEMPLATES[site].urlTemplate) {
        templateIndex++;
        const mr = url.match(template);
        if (mr) {
          if (templateIndex === 0) {
            const index = mr.findIndex(s => s === 'sketchfab.com');
            if (index > -1) {
              return setVideoId(Constants.SKETCH_SITE_TEMPLATES[site].urlPlayer, mr[index + 1]);
            }
          } else if (templateIndex === 1) {
            return setVideoId(Constants.SKETCH_SITE_TEMPLATES[site].urlPlayer, mr[mr.length - 1]);
          } else if (templateIndex === 2) {
            return setVideoId(Constants.SKETCH_SITE_TEMPLATES[site].urlPlayer, mr[0]);
          }
        }
      }
    }
    return null;
  }

  getEasyZoomSiteAndVideoIdFromUrl(url: string): string {
    const setVideoId = (link: string, id: string) => link.replace(/(\{\{\w+}})/, id);
    if (!url) {
      return null;
    }
    let index = -1;
    for (const site of Object.keys(Constants.EASY_ZOOM_SITE_TEMPLATES)) {
      index = -1;
      for (const template of Constants.EASY_ZOOM_SITE_TEMPLATES[site].urlTemplate) {
        index++;
        const mr = url.match(template);
        if (mr) {
          return setVideoId(Constants.EASY_ZOOM_SITE_TEMPLATES[site].urlPlayer, mr[mr.length - 1]);
        }
      }
    }
    return null;
  }

  checkSupportRecordedStreaming(url) {
    if (!url) {
      return false;
    }
    const site = this.getVideoSiteAndVideoIdFromUrl(url, true);
    return !isEmpty(site) && Constants.VIDEO_SITE_RECORDED_STREAMING.includes(site.site);
  }

  isYoutubeRecordedStreaming(url) {
    if (!url) {
      return false;
    }
    const site = this.getVideoSiteAndVideoIdFromUrl(url, true);
    return !isEmpty(site) && site.site.toLowerCase().includes(VIDEO_SITE_RECORDED_STREAMING_TYPES.YOUTUBE);
  }

  isKalturaRecordedStreaming(url) {
    if (!url) {
      return false;
    }
    const site = this.getVideoSiteAndVideoIdFromUrl(url, true);
    return !isEmpty(site) && (site.site.toLowerCase().includes(VIDEO_SITE_RECORDED_STREAMING_TYPES.KALTURA) ||
                              site.site.toLowerCase().includes(VIDEO_SITE_RECORDED_STREAMING_TYPES.ROCHE));
  }

  parseKalturaUrl(url: string): {partnerId?: string, playerId?: string, entryId?: string} {
    const res: {partnerId?: string, playerId?: string, entryId?: string} = {};
    const split = url.split('?');
    const urlPart = split[0];
    const queryPart = split[1];
    if (queryPart && queryPart.includes('entry_id=')) {
      const q = queryPart.split('&');
      const el = q.find(it => it.includes('entry_id='));
      if (el) {
        const values = el.split('=');
        res.entryId = values[1] ? values[1] : null;
      }
    }
    if (urlPart) {
      const u = urlPart.split('/');
      if (u.indexOf('partner_id') !== -1 && u[u.indexOf('partner_id') + 1]) {
        res.partnerId = u[u.indexOf('partner_id') + 1];
      }
      if (u.indexOf('uiconf_id') !== -1 && u[u.indexOf('uiconf_id') + 1]) {
        res.playerId = u[u.indexOf('uiconf_id') + 1];
      }
      if (u.indexOf('entryId') !== -1 && u[u.indexOf('entryId') + 1]) {
        res.entryId = u[u.indexOf('entryId') + 1];
      }
      if (u.indexOf('entry_id') !== -1 && u[u.indexOf('entry_id') + 1]) {
        res.entryId = u[u.indexOf('entry_id') + 1];
      }
    }

    return isEmpty(res) ? null : res;
  }

  roundDateUpSeconds(value: number) {
    const d = new Date(value);
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()).valueOf();
  }

  formatDateDifference(difference: number): string {
    const formatDigit = (value: number, separator: string, printZero = false) => value === 0 ? (!printZero ? '' : '00' + separator) :
      (value >= 1 && value <= 9 ? '0' + value + separator : '' + value.toString() + separator);
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return formatDigit(days, ':') + formatDigit(hours, ':') +
      formatDigit(minutes, ':', true) + formatDigit(seconds, '', true);
  }

  formatDateDifferenceTimeLeft(difference: number, daysLeft: string, timeLeft: String): string {
    const formatDigit = (value: number, separator: string, printZero = false) => value === 0 ? (!printZero ? '' : '00' + separator) :
      (value >= 1 && value <= 9 ? '0' + value + separator : '' + value.toString() + separator);
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return (!days ? '' : days + ' ' + daysLeft + ' ') +
      formatDigit(hours, ':') +
      formatDigit(minutes, ':', true) +
      formatDigit(seconds, '', true) + ' ' + timeLeft;
  }

  capitalize(str: string) {
    return str.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
  }

  replaceAll(str: string, searchValue: string, replaceValue: string) {
    return str.split(searchValue).join(replaceValue);
  }

  isString(obj) {
    return (typeof obj === 'string');
  }

  langWeekdaysReplacer(str: string) {
    if (!str) {
      return str;
    }
    const i18Weekdays = [
      'week.day.monday', 'week.day.tuesday',
      'week.day.wednesday', 'week.day.thursday',
      'week.day.friday', 'week.day.saturday', 'week.day.sunday'
    ];
    const enWeekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    for (let i = 0; i < 7; i++) {
      if (str.includes(enWeekdays[i])) {
        return str.replace(enWeekdays[i], this.i18n(i18Weekdays[i]));
      }
    }
    return str;
  }

  langMonthsReplacer(str: string) {
    if (!str) {
      return str;
    }
    const i18Months = [
      'month.january', 'month.february',
      'month.march', 'month.april',
      'month.may', 'month.june', 'month.july',
      'month.august', 'month.september',
      'month.october', 'month.november', 'month.december'
    ];
    const enMonths = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'];
    for (let i = 0; i < 12; i++) {
      if (str.includes(enMonths[i])) {
        return str.replace(enMonths[i], this.i18n(i18Months[i]));
      }
    }
    return str;
  }

  parseJwt(token) {
    if (!token) {
      return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(payload);
  }

  validateToken(token, timeLag = 0) {
    const currentTime = new Date().getTime();
    let refreshTime = 0;
    const jwt = this.parseJwt(token);
    if (jwt && jwt.exp) {
      refreshTime = (jwt.exp * 1000) - timeLag;
    }
    return refreshTime - currentTime > 0;
  }

  getShortLinkByUrl(url: string, removeQueryParams = true) {
    if (!url) {
      return '';
    }
    let shortLink = '';
    let split = url.split('/');
    split = split.filter(it => it);
    const idx = split.indexOf('event');
    if (idx !== -1 && split.length >= idx + 1) {
      shortLink = split[idx + 1];
    }
    if (removeQueryParams) {
      shortLink = shortLink.split('?')[0];
    }
    return shortLink;
  }

  parseLink(url: string) {
    const res = {type: null, link: null, code: null};
    if (!url) {
      return res;
    }
    if (url.includes('?')) {
      url = url.split('?')[0];
    }
    if (url.startsWith('/')) {
      url = url.substring(1);
    }
    if (url.startsWith('guest')) {
      let split = url.split('/');
      split = split.filter(it => it);
      const guest = split && split.length ? split[0] : null;
      const link = split && split.length > 2 ? split[split.length - 2] : null;
      const code = split && split.length > 2 ? split[split.length - 1] : null;
      res.type = guest;
      res.link = link;
      res.code = code;
    }
    return res;
  }


  getQueryParams(url: string) {
    const value = {};
    const qParams = url.split('?')[1];
    if (qParams) {
      const split = qParams.split('&');
      for (const it of split) {
        const param = it.split('=');
        value[param[0]] = param[1];
      }
    }
    return value;
  }

  getQueryParamValue(url: string, name: string) {
    let value = null;
    const qParams = url.split('?')[1];
    if (qParams) {
      const split = qParams.split('&');
      for (const it of split) {
        const values = it.split('=');
        if (values[0] === name) {
          value = values[1];
        }
      }
    }
    return value;
  }

  htmlToText(text: string) {
    if (!text) {
      return '';
    }
    const div = document.createElement('span');
    div.innerHTML = text;
    return div.innerText;
  }

  getDateDayHoursMinutes(date: number): number {
    if (!date) {
      return date;
    }
    const d = new Date(date);
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes()).getTime();
  }

  getDateDay(date: number): number {
    if (!date) {
      return date;
    }
    const d = new Date(date);
    return new Date(d.getFullYear(), d.getMonth(), d.getDate()).getTime();
  }

  getDateDayEnd(date: number): number {
    if (!date) {
      return date;
    }
    const d = new Date(date);
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59).getTime();
  }

  getDayBetweenDates(dateStart: number, dateEnd: number): number {
    if (!dateStart || !dateEnd) {
      return 0;
    }
    const ds = dateStart - (dateStart % Constants.ONE_DAY);
    const de = dateEnd - (dateEnd % Constants.ONE_DAY);
    return (de - ds) / Constants.ONE_DAY;
  }

  wrapTextWordToSpan(text: string, mainId: string) {
    const words = clone(text);
    let result = '';
    let id = 1;
    let i = 0;
    while (i < words.length) {
      const s = words[i];
      if (s === '<') {
        const closeIndex = words.indexOf('>', i);
        if (closeIndex > -1) {
          result = result + words.substring(i, closeIndex + 1);
          i = closeIndex;
        }
      } else if (s.match(/(\s)/)) {
        result = result + s;
      } else {
        const endWordIndex = words.substring(i).search(/(\s|<)/);
        let subs = '';
        if (endWordIndex > -1) {
          subs = words.substring(i, i + endWordIndex);
          i = i + endWordIndex - 1;
        } else {
          subs = words.substring(i, words.length);
          i = words.length - 1;
        }
        result = result + `<span id="word-${mainId}-${id++}" class="span-word">${subs}</span>`;
      }
      i++;
    }
    return result;
  }

  wrapTextWordToSpanSecurityTrustHtml(text: string, mainId: string) {
    const pipe = new SafePipe(this.sanitizer);
    return pipe.transform(this.wrapTextWordToSpan(text, mainId), 'html');
  }

  // tslint:disable
  ms2TimeString(a, k?, s?, m?, h?) {
    return k = a % 1e3,
      s = a / 1e3 % 60 | 0,
      m = a / 6e4 % 60 | 0,
      h = a / 36e5 % 24 | 0,
    (h ? (h < 10 ? '0' + h : h) + ':' : '') +
    (m < 10 ? 0 : '') + m + ':' +
    (s < 10 ? 0 : '') + s + '.' +
    (k < 100 ? k < 10 ? '00' : 0 : '') + k;
  }
  // timeString2ms('10:21:32.093')
  timeString2ms(a, b) {// time(HH:MM:SS.mss)
    return a = a.split('.'),
      b = a[1] * 1 || 0,
      a = a[0].split(':'),
    b + (a[2] ? a[0] * 3600 + a[1] * 60 + a[2] * 1 : a[1] ? a[0] * 60 + a[1] * 1 : a[0] * 1) * 1e3;
  }
  // tslint:enable

  versionCompare(v1, v2, options?) {
    const lexicographical = options && options.lexicographical;
    const zeroExtend = options && options.zeroExtend;
    let v1parts = v1.split('.');
    let v2parts = v2.split('.');

    function isValidPart(x) {
      return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
      return NaN;
    }

    if (zeroExtend) {
      while (v1parts.length < v2parts.length) { v1parts.push('0'); }
      while (v2parts.length < v1parts.length) { v2parts.push('0'); }
    }

    if (!lexicographical) {
      v1parts = v1parts.map(Number);
      v2parts = v2parts.map(Number);
    }

    for (let i = 0; i < v1parts.length; ++i) {
      if (v2parts.length === i) {
        return 1;
      }

      if (v1parts[i] === v2parts[i]) {
        continue;
      } else if (v1parts[i] > v2parts[i]) {
        return 1;
      } else {
        return -1;
      }
    }

    if (v1parts.length !== v2parts.length) {
      return -1;
    }

    return 0;
  }

  ISODateString(d) {
    function pad(n) {
      return n < 10 ? '0' + n : n;
    }
    return d.getUTCFullYear() + '-'
      + pad(d.getUTCMonth() + 1) + '-'
      + pad(d.getUTCDate()) + 'T'
      + pad(d.getUTCHours()) + ':'
      + pad(d.getUTCMinutes()) + ':'
      + pad(d.getUTCSeconds()) + 'Z';
  }

  parseDuration(str: string, format: Units = 'm'): number | null {
    const duration = /(-?(?:\d+\.?\d*|\d*\.?\d+)(?:e[-+]?\d+)?)\s*([a-zµμ]*)/ig;
    const parse = new ParseDuration();
    let result = null;
    // ignore commas
    str = str.replace(/(\d),(\d)/g, '$1$2');
    let parseError = false;
    str.replace(duration, function(_, n, units) {
      if (units === '') {
        units = 'm';
      }
      units = parse[units] || parse[units.toLowerCase().replace(/s$/, '')];
      if (units) {
        result = (result || 0) + parseFloat(n) * units;
      } else {
        parseError = true;
      }
      return result;
    });
    if (!parseError) {
      return result && (result / parse[format]);
    } else {
      return NaN;
    }
  }

  isElementVisible(target, parent) {
    const targetRect = target.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();
    if (
      targetRect.bottom > parentRect.bottom ||
      targetRect.top < parentRect.top ||
      targetRect.right > parentRect.right ||
      targetRect.left < parentRect.left) {
      return false;
    } else {
      return true;
    }
  }

  getURL(url: string) {
    const pipe = new SafePipe(this.sanitizer);
    return pipe.transform(url);
  }

  /**
   * return language list registered in timeline and can be apply for user interface
   */
  getLanguageList(): ILanguage[] {
    return Constants.LANGUAGE_LIST.map(l => (new Object({name: this.i18n(l.name), value: l.value})) as ILanguage)
      .sort(this.comparator('name'));
  }

  getBrowserLanguage() {
    return navigator.language ? navigator.language.substring(0, 2) : null;
  }

  /**
   * Check language is registered in timeline and can be apply for user interface
   * @param language - short language name only first two symbols
   */
  checkBrowserLanguage(language: string) {
    return !!Constants.LANGUAGE_LIST.find(o => o.value === language);
  }

  playMusicFile(sample: string) {
    const loadSound = () => {
      const audioURL = sample;
      const request = new XMLHttpRequest();
      request.open('GET', audioURL, true);
      request.responseType = 'arraybuffer';
      request.onload = function () {
        context.decodeAudioData(request.response, function (buffer) {
          playSound(buffer);
        });
      };
      request.send();
    };
    const playSound = (buffer) => {
      const bufferSource = context.createBufferSource();
      bufferSource.buffer = buffer;
      bufferSource.connect(context.destination);
      bufferSource.start(0);
    };
    let context;
    try {
      context = new (window['AudioContext'] || window['webkitAudioContext'])();
      if (!context) {
        console.log('Your browser doesn\'t support Web Audio API');
      }
    } catch (e) {
      console.log('Your browser doesn\'t support Web Audio API', e);
    }
    loadSound();
  }

  convertStringToHTMLWrapWordsToSpan(text: string, resultStringId: string) {
    let result = this.createTextSplitLinks(text);
    result = this.createTextLinksM(result);
    result = this.createTextLinks(result);
    result = this.convertNewLinesToBr(result);
    return this.wrapTextWordToSpan(result, resultStringId);
  }

  convertStringToHTML(text: string) {
    let result = this.createTextSplitLinks(text);
    result = this.createTextLinksM(result);
    return this.createTextLinks(result);
  }

  getSectionChildrenTree(section: SectionContent, sectionsList: SectionTimeline[] | SectionContent[]) {
    const getChildren = (parent: SectionContent, tree: SectionContent[]) => {
      sectionsList.filter(s => s.parentId === parent.id)
        .forEach(c => {
          tree.push(c);
          getChildren(c, tree);
        });
    };
    const childrenTree = [section];
    getChildren(section, childrenTree);
    return childrenTree.sort(this.comparator(Constants.ORDERINDEX));
  }

  extractFileNameFromUrl(url: string) {
    const decodeUrl = (encode) => {
      if (encode == null) {
        return '';
      }
      return encode.replace(/%21/g, '!')
        .replace(/%20/g, ' ')
        .replace(/%23/g, '#')
        .replace(/%24/g, '$')
        .replace(/%26/g, '&')
        .replace(/%27/g, '\'')
        .replace(/%28/g, '(')
        .replace(/%29/g, ')')
        .replace(/%2A/g, '*')
        .replace(/%2B/g, '+')
        .replace(/%2C/g, ',')
        .replace(/%2F/g, '/')
        .replace(/%3A/g, ':')
        .replace(/%3B/g, ';')
        .replace(/%3D/g, '=')
        .replace(/%3F/g, '?')
        .replace(/%40/g, '@')
        .replace(/%5B/g, '[')
        .replace(/%5D/g, ']')
        .replace(/%25/g, '%');
    };

    if (url) {
      const sanitizeUrl = decodeUrl(url);
      const path = sanitizeUrl.split('?')[0];
      const splitPath = path.split('/');
      return splitPath[splitPath.length - 1];
    }
    return url;
  }

  addTimeToFileName(fileName: string) {
    const names = fileName.split('.');
    return names.length > 1 ?
      fileName.replace(`.${names[names.length - 1]}`, `-${new Date().getTime()}.${names[names.length - 1]}`) :
      fileName + `-${new Date().getTime()}`;
  }

  convertHexToRGBA(hex: string, opacity: number) {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
}

class ParseDuration {
  private _minute = 1;

  get m() {
    return this._minute;
  }

  set m(value) {
    this._minute = value;
  }

  get minute() {
    return this._minute;
  }

  get min() {
    return this._minute;
  }

  get hour() {
    return this.m * 60;
  }
  get hr() {
    return this.m * 60;
  }
  get h() {
    return this.m * 60;
  }

  get day() {
    return this.h * 24;
  }
  get d() {
    return this.h * 24;
  }

  get week() {
    return this.d * 7;
  }
  get wk() {
    return this.d * 7;
  }
  get w() {
    return this.d * 7;
  }

  get month() {
    return this.d * (365.25 / 12);
  }

  get mo() {
    return this.d * (365.25 / 12);
  }

  get year() {
    return this.m * 525600.00000715;
  }
  get yr() {
    return this.m * 525600.00000715;
  }
  get y() {
    return this.m * 525600.00000715;
  }
}
