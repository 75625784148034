import {Constants, ILanguageParams, TEMPLATE_TYPE} from '../../../../../../core/constants';
import {
  QuestionCheckChoiceEditorComponent
} from '../question-check-choice/question-check-choice-editor/question-check-choice-editor.component';
import {QuestionScaleEditorComponent} from '../question-scale/question-scale-editor/question-scale-editor.component';
import {QuestionWordCloudEditorComponent} from '../question-word-cloud/question-word-cloud-editor/question-word-cloud-editor.component';
import {QuestionMatchingEditorComponent} from '../question-matching/question-matching-editor/question-matching-editor.component';
import {
  QuestionSelectGapFillingEditorComponent
} from '../question-select-gap-filling/question-select-gap-filling-editor/question-select-gap-filling-editor.component';
import {
  QuestionTextGapFillingEditorComponent
} from '../question-text-gap-filling/question-text-gap-filling-editor/question-text-gap-filling-editor.component';
import {
  QuestionCheckboxGapFillingEditorComponent
} from '../question-checkbox-gap-filling/question-checkbox-gap-filling-editor/question-checkbox-gap-filling-editor.component';
import {
  QuestionMapMathingEditorComponent
} from '../question-map-matching/question-map-mathing-editor/question-map-mathing-editor.component';
import {
  QuestionCheckChoiceParticipantComponent
} from '../question-check-choice/question-check-choice-participant/question-check-choice-participant.component';
import {
  QuestionMatchingParticipantComponent
} from '../question-matching/question-matching-participant/question-matching-participant.component';
import {QuestionTextParticipantComponent} from '../question-text/question-text-participant/question-text-participant.component';
import {QuestionScaleParticipantComponent} from '../question-scale/question-scale-participant/question-scale-participant.component';
import {
  QuestionSelectGapFillingParticipantComponent
} from '../question-select-gap-filling/question-select-gap-filling-participant/question-select-gap-filling-participant.component';
import {
  QuestionTextGapFillingParticipantComponent
} from '../question-text-gap-filling/question-text-gap-filling-participant/question-text-gap-filling-participant.component';
import {
  QuestionCheckboxGapFillingParticipantComponent
} from '../question-checkbox-gap-filling/question-checkbox-gap-filling-participant/question-checkbox-gap-filling-participant.component';
import {
  QuestionMapMatchingParticipantComponent
} from '../question-map-matching/question-map-mathing-participant/question-map-matching-participant.component';
import {
  QuestionCheckChoiceResultsComponent
} from '../question-check-choice/question-check-choice-results/question-check-choice-results.component';
import {QuestionMatchingResultsComponent} from '../question-matching/question-matching-results/question-matching-results.component';
import {QuestionTextResultsComponent} from '../question-text/question-text-results/question-text-results.component';
import {QuestionWordCloudResultsComponent} from '../question-word-cloud/question-word-cloud-results/question-word-cloud-results.component';
import {QuestionScaleResultsComponent} from '../question-scale/question-scale-results/question-scale-results.component';
import {
  QuestionSelectGapFillingResultsComponent
} from '../question-select-gap-filling/question-select-gap-filling-results/question-select-gap-filling-results.component';
import {
  QuestionTextGapFillingResultsComponent
} from '../question-text-gap-filling/question-text-gap-filling-results/question-text-gap-filling-results.component';
import {
  QuestionCheckboxGapFillingResultsComponent
} from '../question-checkbox-gap-filling/question-checkbox-gap-filling-results/question-checkbox-gap-filling-results.component';
import {
  QuestionMapMatchingResultsComponent
} from '../question-map-matching/question-map-matching-results/question-map-matching-results.component';
import {AnswerEquality, AnswerQuestion, EventQuestion} from '../../../../../../model/EventQuestion';
import {
  checkFeedbackCheckboxGapFilling,
  checkFeedbackCheckChoice,
  checkFeedbackGapFillingMatchingMap,
  checkFeedbackMatching,
  checkFeedbackText,
  checkFeedbackTextGapFilling
} from './lib/quiz-question-feedback-lib';
import {
  tooltipQuestionTypeCheckboxGapFilling,
  tooltipQuestionTypeCheckChoice,
  tooltipQuestionTypeMatching,
  tooltipQuestionTypeMatchingMap,
  tooltipQuestionTypeScale,
  tooltipQuestionTypeSelectGapFilling,
  tooltipQuestionTypeText, tooltipQuestionTypeTextBalloons,
  tooltipQuestionTypeTextGapFilling
} from './lib/quiz-question-tooltip-lib';
import {QuestionTextEditorComponent} from '../question-text/question-text-editor/question-text-editor.component';
import {Quiz} from '../../quiz-model/quiz';
import {
  QuestionTextBalloonsEditorComponent
} from '../question-text-balloons/question-text-balloons-editor/question-text-balloons-editor.component';
import {
  QuestionTextBalloonsResultsComponent
} from '../question-text-balloons/question-text-balloons-results/question-text-balloons-results.component';
import {
  QuestionTextBalloonsParticipantComponent
} from '../question-text-balloons/question-text-balloons-participant/question-text-balloons-participant.component';
import {
  QuestionCheckChoiceParticipantLearnComponent
} from '../question-check-choice/question-check-choice-participant-learn/question-check-choice-participant-learn.component';
import {textBalloonsAIAction} from './lib/quiz-question-ai-lib';
import {textBalloonsCloudView, textBalloonsListView} from './lib/quiz-question-extended-actions';

export enum QUESTION_TYPE {
  CHECK = Constants.QTYPE_CHECK,
  CHOICE = Constants.QTYPE_CHOICE,
  TEXT = Constants.QTYPE_TEXT,
  WORD_CLOUD = Constants.QTYPE_WORD_CLOUD,
  MATCHING = Constants.QTYPE_MATCHING,
  SCALE = Constants.QTYPE_SCALE,
  SELECT_GAP_FILLING = Constants.QTYPE_SELECT_GAP_FILLING,
  TEXT_GAP_FILLING = Constants.QTYPE_TEXT_GAP_FILLING,
  CHECKBOX_GAP_FILLING = Constants.QTYPE_CHECKBOX_GAP_FILLING,
  MATCHING_MAP = Constants.QTYPE_MATCHING_MAP,
  TEXT_BALLOONS = Constants.QTYPE_TEXT_BALLON
}

export enum SUMMARY_SAVE_TYPE {
  MERGE = 'merge',
  OVERWRITE = 'overwrite',
  TEXT_COLLECTION = 'text_collection'
}

export const QUESTIONS_TYPES_INCLUDE_FILES = [QUESTION_TYPE.WORD_CLOUD, QUESTION_TYPE.MATCHING_MAP];

export const TEMPLATE_STORAGE_PATH = {
  [TEMPLATE_TYPE.USER_TEMPLATE]: 'app_users/{userId}/quiz-template/{templateId}',
  [TEMPLATE_TYPE.PUBLISHED_COMPANY]: 'quiz-template/{templateId}',
};

export interface IQuizTemplate {
  id?: string; // if new template id can be null
  title: string;
  description: string;
  icon: string;
  type: TEMPLATE_TYPE;
  template?: Quiz;
}

export interface IExtendedPoolAction {
  action: string;
  actionTitle: string;
  actionIcon: string;
  actionMethod: (...params) => void;
}

export interface IExtQuizTemplate extends IQuizTemplate {
  findAnnotation: string;
}

type TQuestionEditorComponentClass = typeof QuestionCheckChoiceEditorComponent | typeof QuestionScaleEditorComponent |
                                     typeof QuestionWordCloudEditorComponent | typeof QuestionMatchingEditorComponent |
                                     typeof QuestionSelectGapFillingEditorComponent | typeof QuestionTextGapFillingEditorComponent |
                                     typeof QuestionCheckboxGapFillingEditorComponent | typeof QuestionMapMathingEditorComponent |
                                     typeof QuestionTextEditorComponent | typeof QuestionTextBalloonsEditorComponent;
type TQuestionParticipantComponentClass = typeof QuestionCheckChoiceParticipantComponent | typeof QuestionMatchingParticipantComponent |
                                     typeof QuestionTextParticipantComponent | typeof QuestionScaleParticipantComponent |
                                     typeof QuestionSelectGapFillingParticipantComponent |
                                     typeof QuestionTextGapFillingParticipantComponent |
                                     typeof QuestionCheckboxGapFillingParticipantComponent |
                                     typeof QuestionCheckChoiceParticipantLearnComponent |
                                     typeof QuestionMapMatchingParticipantComponent | typeof QuestionTextBalloonsParticipantComponent;
type TQuestionResultsComponentClass = typeof QuestionCheckChoiceResultsComponent | typeof QuestionMatchingResultsComponent |
                                     typeof QuestionTextResultsComponent | typeof QuestionWordCloudResultsComponent |
                                     typeof QuestionScaleResultsComponent | typeof QuestionSelectGapFillingResultsComponent |
                                     typeof QuestionTextGapFillingResultsComponent | typeof QuestionCheckboxGapFillingResultsComponent |
                                     typeof QuestionMapMatchingResultsComponent | typeof QuestionTextBalloonsResultsComponent;

export interface IQuestionComponent {
  /**
   * Class (html) form for editing a question
   */
  editorComponentClass: TQuestionEditorComponentClass;
  /**
   * Class (html) form for user answers to questions
   */
  participantComponentClass: TQuestionParticipantComponentClass;
  /**
   * Class (html) form for user answers to questions
   */
  participantLearnComponentClass?: TQuestionParticipantComponentClass;
  /**
   * Class (html) form of statistics/results of user responses to questions.
   */
  resultsComponentClass: TQuestionResultsComponentClass;
  /**
   * the name of the server method for creating a collection of summary information on users answers.
   */
  questionAnswersSummaryMethodName: (question?: EventQuestion) => string;
  /**
   * the way to save the results. usually the results are merged when saved,
   * but in some cases the results must be completely overwritten or saved in a separate collection.
   */
  questionAnswersSummarySaveType: (question?: EventQuestion) => SUMMARY_SAVE_TYPE;
  /**
   * showing or hiding other questions may depend on the answers to this question
   */
  canUseInDependency?: boolean;
  /**
   * this question type can use in registration questionnaire
   */
  canUseInRegistration?: boolean;
  /**
   * delay before send answer, default no delay.
   */
  sendAnswersDebounceTime?: number;
  /**
   * answers mode:
   * FALSE: “Survey” - it doesn't matter what you answer, it’s just a survey.
   * TRUE: "Knowledge Test", etc. - you must answer the question correctly.
   */
  useCorrectAnswersOption?: boolean;
  /**
   * a method for checking the correct answers.
   * if the method is not defined the question is working as answer mode only “Survey”
   * it can work in combination with the useCorrectAnswersOption setting
   */
  directFeedbackCheck?: (answers, items: AnswerQuestion[],
                         correctEquality?: AnswerEquality[], languageParams?: ILanguageParams) => boolean | null;
  /**
   * a method for creating a tooltip and cell string from user answers to display in the results table.
   */
  answersTooltip?: (question: EventQuestion, answers: any, languageParams?: ILanguageParams) => string;

  /**
   * parameters are currently under development
   */
  aiAction?: (...params) => void;
  extendedQuestionTypeActions?: IExtendedPoolAction[];
}

export interface IQuestionComponentsMap {
  [type: string]: IQuestionComponent;
}

export const QUESTION_TYPES_COMPONENTS: IQuestionComponentsMap = {
  [QUESTION_TYPE.CHECK]: {
    editorComponentClass: QuestionCheckChoiceEditorComponent,
    participantComponentClass: QuestionCheckChoiceParticipantComponent,
    resultsComponentClass: QuestionCheckChoiceResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryCheckChoice',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    canUseInDependency: true,
    canUseInRegistration: true,
    useCorrectAnswersOption: true,
    directFeedbackCheck: checkFeedbackCheckChoice,
    answersTooltip: tooltipQuestionTypeCheckChoice
  },
  [QUESTION_TYPE.CHOICE]: {
    editorComponentClass: QuestionCheckChoiceEditorComponent,
    participantComponentClass: QuestionCheckChoiceParticipantComponent,
    participantLearnComponentClass: QuestionCheckChoiceParticipantLearnComponent,
    resultsComponentClass: QuestionCheckChoiceResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryCheckChoice',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    canUseInDependency: true,
    canUseInRegistration: true,
    useCorrectAnswersOption: true,
    directFeedbackCheck: checkFeedbackCheckChoice,
    answersTooltip: tooltipQuestionTypeCheckChoice
  },
  [QUESTION_TYPE.SCALE]: {
    editorComponentClass: QuestionScaleEditorComponent,
    participantComponentClass: QuestionScaleParticipantComponent,
    resultsComponentClass: QuestionScaleResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryScale',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    answersTooltip: tooltipQuestionTypeScale
  },
  [QUESTION_TYPE.TEXT]: {
    editorComponentClass: QuestionTextEditorComponent,
    participantComponentClass: QuestionTextParticipantComponent,
    resultsComponentClass: QuestionTextResultsComponent,
    questionAnswersSummaryMethodName: (q) => q?.useCorrectAnswers ? 'questionSummaryCorrectText' : 'questionSummaryText',
    questionAnswersSummarySaveType: (q) => q?.useCorrectAnswers ? SUMMARY_SAVE_TYPE.OVERWRITE : SUMMARY_SAVE_TYPE.TEXT_COLLECTION,
    directFeedbackCheck: checkFeedbackText,
    answersTooltip: tooltipQuestionTypeText,
    useCorrectAnswersOption: true,
    canUseInRegistration: true,
    sendAnswersDebounceTime: 700
  },
  [QUESTION_TYPE.WORD_CLOUD]: {
    editorComponentClass: QuestionWordCloudEditorComponent,
    participantComponentClass: QuestionTextParticipantComponent,
    resultsComponentClass: QuestionWordCloudResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryText',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.TEXT_COLLECTION,
    answersTooltip: tooltipQuestionTypeText,
    sendAnswersDebounceTime: 700
  },
  [QUESTION_TYPE.MATCHING]: {
    editorComponentClass: QuestionMatchingEditorComponent,
    participantComponentClass: QuestionMatchingParticipantComponent,
    resultsComponentClass: QuestionMatchingResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryMatching',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    directFeedbackCheck: checkFeedbackMatching,
    answersTooltip: tooltipQuestionTypeMatching
  },
  [QUESTION_TYPE.SELECT_GAP_FILLING]: {
    editorComponentClass: QuestionSelectGapFillingEditorComponent,
    participantComponentClass: QuestionSelectGapFillingParticipantComponent,
    resultsComponentClass: QuestionSelectGapFillingResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummarySelectGapFilling',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    directFeedbackCheck: checkFeedbackGapFillingMatchingMap,
    answersTooltip: tooltipQuestionTypeSelectGapFilling
  },
  [QUESTION_TYPE.TEXT_GAP_FILLING]: {
    editorComponentClass: QuestionTextGapFillingEditorComponent,
    participantComponentClass: QuestionTextGapFillingParticipantComponent,
    resultsComponentClass: QuestionTextGapFillingResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryTextGapFilling',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.OVERWRITE,
    directFeedbackCheck: checkFeedbackTextGapFilling,
    answersTooltip: tooltipQuestionTypeTextGapFilling
  },
  [QUESTION_TYPE.CHECKBOX_GAP_FILLING]: {
    editorComponentClass: QuestionCheckboxGapFillingEditorComponent,
    participantComponentClass: QuestionCheckboxGapFillingParticipantComponent,
    resultsComponentClass: QuestionCheckboxGapFillingResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryCheckChoice',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    directFeedbackCheck: checkFeedbackCheckboxGapFilling,
    answersTooltip: tooltipQuestionTypeCheckboxGapFilling
  },
  [QUESTION_TYPE.MATCHING_MAP]: {
    editorComponentClass: QuestionMapMathingEditorComponent,
    participantComponentClass: QuestionMapMatchingParticipantComponent,
    resultsComponentClass: QuestionMapMatchingResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryMatching',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    directFeedbackCheck: checkFeedbackGapFillingMatchingMap,
    answersTooltip: tooltipQuestionTypeMatchingMap
  },
  [QUESTION_TYPE.TEXT_BALLOONS]: {
    editorComponentClass: QuestionTextBalloonsEditorComponent,
    participantComponentClass: QuestionTextBalloonsParticipantComponent,
    resultsComponentClass: QuestionTextBalloonsResultsComponent,
    questionAnswersSummaryMethodName: (q) => q?.useCorrectAnswers ? 'questionSummaryCorrectTextBalloons' : 'questionSummaryTextBalloons',
    questionAnswersSummarySaveType: (q) => q?.useCorrectAnswers ? SUMMARY_SAVE_TYPE.OVERWRITE : SUMMARY_SAVE_TYPE.TEXT_COLLECTION,
    useCorrectAnswersOption: false,
    answersTooltip: tooltipQuestionTypeTextBalloons,
    sendAnswersDebounceTime: 700,
    aiAction: textBalloonsAIAction,
    extendedQuestionTypeActions: [
      {action: 'list-view', actionTitle: 'questionnaire.list_view', actionIcon: 'ti-layout-list', actionMethod: textBalloonsListView},
      {action: 'cloud-view', actionTitle: 'questionnaire.cloud_view', actionIcon: 'ti-cloud', actionMethod: textBalloonsCloudView}
    ]
  }
};

export interface IValidated {
  validated: boolean;
  warning?: string;
}

